@import "../font-awesome/variables";
@import "../font-awesome/mixins";
@import "../bootstrap/mixins";

$input-color-placeholder:             #cccccc;

$padding-base-horizontal:             13px;
$padding-base-vertical:               7px;

$color-select-icon:                   #666666;
$color-select-border:                 #b2b9be;
$color-select-border-focus:           #808c8d;
$color-select-bg:                     #f6f6f6;
$color-select-hover-bg:               #f5f5f5;
$color-select-hover:                  darken(#777, 5%);
$color-select-active:                 #fff;
$color-select-active-bg:              #4da7e8;
$color-select-header:                 #777;
$color-select-link-disabled:          #777;
$color-select-choice-bg:              #fff;
$color-select-choice-border:          #ccc;
$input-border-fade:                   fade(#d1d6d9, 25%);

$select-box-shadow:                   0 3px 6px rgba(0, 0, 0, .075);
$select-box-shadow-above:             0 -3px 6px rgba(0, 0, 0, .075);
$select-cursor-disabled:              not-allowed;

@mixin select-input-defaults() {
    background-color: #fff;
    border: 1px solid #a2a2a2;
    font-size: 14px;
    @include box-shadow(inset 0 1px 0 $input-border-fade, 0 1px 0 rgba(255,255,255,.5));
}

.select2-container {
    .loading-indicator {
        background: transparent;

        > span {
            background-image: url('../img/loader-transparent.svg');
            left: auto;
            right: 10px;
            top: 19px;
            background-size: 17px 17px;
        }
    }

    &.in-progress {
        .select2-selection .select2-selection__arrow b {
            display: none !important;
        }
    }
}

.select2-container--default {
    display: block;

    .select2-selection {
        @include select-input-defaults();
        outline: 0;
    }

    .select2-search--dropdown {
        position: relative;

        &:after {
            position: absolute;
            @include icon($fa-var-search);
            right: 13px;
            top: 9px;
            color: #95a5a6;
        }

        .select2-search__field {
            @include select-input-defaults();
        }
    }

    .select2-search__field {
        outline: 0;

        &::-webkit-input-placeholder {
            color: $input-color-placeholder;
        }

        &:-moz-placeholder {
            color: $input-color-placeholder;
        }

        &::-moz-placeholder {
            color: $input-color-placeholder;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $input-color-placeholder;
        }
    }

    .select2-results__option {

        &[role=group] {
            padding: 0;
        }

        &[aria-disabled=true] {
            color: $color-select-link-disabled;
            cursor: $select-cursor-disabled;
        }

        &[aria-selected=true] {
            background-color: $color-select-hover-bg;
            color: $color-select-hover;
        }

        &--highlighted[aria-selected] {
            background-color: $color-select-active-bg;
            color: $color-select-active;
        }

        .select2-results__option {
            padding: $padding-base-vertical $padding-base-horizontal;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -$padding-base-horizontal;
                padding-left: $padding-base-horizontal*2;

                .select2-results__option {
                    margin-left: -$padding-base-horizontal*2;
                    padding-left: $padding-base-horizontal*3;

                    .select2-results__option {
                        margin-left: -$padding-base-horizontal*3;
                        padding-left: $padding-base-horizontal*4;

                        .select2-results__option {
                            margin-left: -$padding-base-horizontal*4;
                            padding-left: $padding-base-horizontal*5;

                            .select2-results__option {
                                margin-left: -$padding-base-horizontal*5;
                                padding-left: $padding-base-horizontal*6;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__group {
        color: $color-select-header;
        display: block;
        padding: $padding-base-vertical $padding-base-horizontal;
        font-size: 14px;
        line-height: 1.6;
        white-space: nowrap;
    }

    &.select2-container--focus,
    &.select2-container--open {
        .select2-selection {
            @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
            border-color: #d1d6d9;
        }
    }

    &.select2-container--open {
        //
        // Make the dropdown arrow point up while the dropdown is visible.
        //
        .select2-selection .select2-selection__arrow b {
            &:before {
                @include icon($fa-var-angle-up);
            }
        }

        //
        // Handle border radii of the container when the dropdown is showing.
        //
        &.select2-container--below {
            .select2-selection:not(.select-no-dropdown) {
                @include border-bottom-radius(0);
                border-bottom-color: transparent;
            }
        }

        &.select2-container--above {
            .select2-selection:not(.select-no-dropdown) {
                @include border-top-radius(0);
                border-top-color: transparent;
            }
        }
    }

    .select2-selection__clear {
        color: $color-select-icon;
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-right: 10px;

        &:hover {
            color: #333;
        }
    }

    &.select2-container--disabled {

        .select2-selection {
            border-color: #d1d6d9;
            @include box-shadow(none);
        }

        .select2-selection,
        .select2-search__field {
            cursor: $select-cursor-disabled;
        }

        .select2-selection,
        .select2-selection--multiple .select2-selection__choice {
            background-color: #eee;
        }

        .select2-selection__clear,
        .select2-selection--multiple .select2-selection__choice__remove {
            display: none;
        }
    }

    // Dropdown
    //------------------------------------

    .select2-dropdown {
        @include box-shadow($select-box-shadow);
        border-color: #d1d6d9;
        overflow-x: hidden;
        margin-top: -1px;
        &--above {
            margin-top: 1px;
            @include box-shadow($select-box-shadow-above);
        }
    }

    .select2-results > .select2-results__options {
        font-size: 13px;
        max-height: 200px;
        overflow-y: auto;
    }

    // No Dropdown
    //------------------------------------

    .select2-dropdown.select-no-dropdown {
        display: none !important;
    }

    .select2-dropdown.select-hide-selected {
        li[aria-selected=true] {
            display: none !important;
        }
    }

    // Single select
    //------------------------------------

    .select2-selection--single {
        height: 38px;
        line-height: 1.6;
        padding: $padding-base-vertical $padding-base-horizontal + 12 $padding-base-vertical $padding-base-horizontal;

        //
        // Adjust the single Select2's dropdown arrow button appearance.
        //
        .select2-selection__arrow {
            position: absolute;
            bottom: 0;
            right: $padding-base-horizontal;
            top: 0;
            width: 4px;

            b {
                position: absolute;
                top: 50%;
                height: 9px;
                width: 8px;
                right: 3px;
                margin-top: -5px;
                line-height: 9px;
                &:before {
                    @include icon($fa-var-angle-down);
                    display: inline-block;
                }
            }
        }

        .select2-selection__rendered {
            padding: 0;
        }

        .select2-selection__placeholder {
            color: #cccccc;
        }
    }

    // Multiple select
    //------------------------------------

    .select2-selection--multiple {
        min-height: 38px;

        .select2-selection__rendered {
            box-sizing: border-box;
            display: block;
            line-height: 1.6;
            list-style: none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .select2-selection__placeholder {
            color: $input-color-placeholder;
            float: left;
            margin-top: 5px;
        }

        //
        // Make Multi Select2's choices match default button styles.
        //
        .select2-selection__choice {
            background: $color-select-choice-bg;
            border: 1px solid $color-select-choice-border;
            border-radius: 4px;
            cursor: default;
            float: left;
            margin: 7px 0 0 $padding-base-horizontal/2;
            padding: 0 $padding-base-vertical;
        }

        //
        // Minus 2px borders.
        //
        .select2-search--inline {
            .select2-search__field {
                background: transparent;
                padding: 0 $padding-base-horizontal;
                height: 36px;
                line-height: 1.6;
                margin-top: 0;
                min-width: 5em;
            }
        }

        .select2-selection__choice__remove {
            color: #999;
            cursor: pointer;
            display: inline-block;
            font-weight: bold;
            margin-right: 4px;

            &:hover {
                color: #333;
            }
        }

        .select2-selection__clear {
            margin-top: 8px;
        }
    }

}

.form-control.select2-hidden-accessible {
    position: absolute !important;
    width: 1px !important;
}

// Inline
//------------------------------------

.form-inline .select2-container--default {
    display: inline-block;
}
