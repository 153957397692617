@import url('https://fonts.googleapis.com/css?family=Mulish:200,300,400,500,600,700,800,900|Mulish:200italic,300italic,400italic,500italic,600italic,700italic,800italic,900italic&display=swap');

$primary-color: #5E8ACC;
$secondary-color: #ADCEFF;
$infopanel-background: #444865;

$white: #FFFFFF;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-700: #374151;
$gray-800: #1f2937;
.wrapper-body {
    
    &.oHidden {
        margin-top: 0;
    }

    &.login-body {
        background-image: url("../img/hexagon-full.svg");
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;

        & .control-label {
            display: none;
        }

        & .form-group {
            min-height: 30px;
            margin-top: 10px;
        }

    }
}

.box-wrapper {
    margin-top: 100px;
}
.maxWidth {
    width: 100% !important;
}
.headerBelowLogoBiggerText{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    > h1 {
        font-size: 24px !important;
    }
}

.login-container {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .login-wrapper {
    background-color: $white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    width: 100%;
    max-width: 64rem;
    display: flex;
  }
  
  .centerForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .login-form {
    width: 50%;
    padding: 2rem;
    font-family: "Mulish", sans-serif !important;
  
    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      color: $gray-800;
      margin: 1.5rem 0 0 1rem;
    }
  
    .form-group {
      margin-bottom: 1rem;
  
      label {
        display: block;
        font-size: 0.875rem;
        font-weight: 500;
        color: $gray-700;
        margin-bottom: 0.5rem;
      }
  
      input {
        width: 100%;
        padding: 0.5rem 0.75rem;
        border: 1px solid $gray-200;
        border-radius: 0.25rem;
        font-size: 0.875rem;
  
        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        }
        &:hover {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        }
      }
    }

    .cta-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .cta-title {
        margin: 0.5rem 0;
      }

      .cta-desc {
        font-size: 0.8rem; /* Smaller text for the description */
        margin: 0.5rem 0; /* Adds space between the title and description */
        color: #FFFFFF; /* Lighter color for the description */
      }
  
      .btn-trial {
        text-align: center;
        background: #FFFFFF;
        color: #15C993;
        border: 1px solid #15C993;
        text-decoration: none;
        width: 100%;
        margin-top: 0.5rem;
        padding: 0.5em;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s ease;
        text-transform: uppercase;
  
        &:hover {
          background: #15C993;
          color: $white;
        }
      }
    }
  
    .remember-forgot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .checkbox-wrapper {
        display: flex;
        align-items: center;
        transform: translate(0, -5px);
        input {
          margin-right: 0.5rem;
        }
        .checkbox {
          label::before, label::after {
            margin-top:3px;
          }
          & input[type="checkbox"]:focus + label::before, & input[type="radio"]:focus + label::before {
            outline: none;
          }
        }
      }
      a {
        color: $primary-color;
        text-decoration: none;
        font-size: 0.875rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
  }
  
  .product-info {
    width: 50%;
    background: $infopanel-background;
    color: $white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Mulish", sans-serif !important;

    .login-img-responsive{
      width: 15rem;
      margin-bottom: 0.5rem;
    }
  
    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
  
      img {
        margin-right: 0.5rem;
        max-width: 70% !important;
      }
  
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  
    h3 {
      font-size: 1.875rem;
    color: $white;
    font-weight: 700;
      margin-bottom: 1rem;
      text-shadow: 0px 1.2px 1px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(20, 19, 36, 0.1);
    }
  
    p {
    color: $white;
    font-size: 1rem;
      margin-bottom: 1.5rem;
      text-shadow: 0px 1.2px 1px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(20, 19, 36, 0.1);
    }
  
    .feature-list {
      margin-bottom: 2rem;
  
      .feature-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.75rem;
        color: $white;
        font-weight: 500;
        
  
        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .greenButton{
    background-color: #15C993 !important;
  }
  .btn-login {
    width: 100%;
    padding: 0.5em;
    background-color: #15C993;
    color: $white;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &.twofa {
      padding: 6px 16px;
    }

    &:hover {
      background-color: darken(#15C993, 10%);
      color: $white;
    }
  }
  .centerPadding {
    padding: 6px !important;
    text-align: center !important;
  }
  .btn-back-login-secondary {
    width: 30%;
    padding: 6px 12px;
    background-color: #15C993 !important;
    color: $white;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 0.875rem;
    cursor: pointer;
    margin-left: 0;
    transition: background-color 0.2s ease;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    &.large {
      width: 100%;
      margin: 0 20px 0 20px;
    }

    &:hover {
      background-color: darken(#15C993, 10%);
      text-decoration: none;
      color: $white;
    }
    
    &:disabled {
      background-color: darken(#15C993, 10%);
      text-decoration: none;
      color: $white;
    }
  }
  .btn-back-login {
    width: max-content;
    padding: 6px 12px;
    //background-color: #15C993;
    color: #15C993;
    border: 1px solid #15C993;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 0.875rem;
    cursor: pointer;
    margin-left: 0;
    transition: background-color 0.2s ease;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    &.large {
      width: 100%;
      margin: 0 20px 0 20px;
    }

    &:hover {
      background-color: #15C993;
      text-decoration: none;
      color: $white;
    }

    &:disabled {
      background-color: darken(#15C993, 10%);
      text-decoration: none;
      color: $white;
    }
  }
.footer-middle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;

    p{
      margin-top: 0.5rem;
  }
}
.boxes{
    width: 768px;
    margin: auto;
    display: flex;
    justify-content: center;
    justify-content: center;
}
.login-box{
    width: 50% !important;
    margin: auto;
    text-align: center;
}
.welcome-box{
    width: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 40px;
    text-align: center;
    height: 100% !important;
    border-top-color: #15c993;
    border-right-color: #15c993;
    border-bottom-color: #15c993;
    border-left-color: #15c993;
    background-image: linear-gradient(150deg, #8ec1f3, #15c993);
	color: #FFFFFF;

    h1{
        font-size: 28px !important;
        font-weight: 500;
    }
    .whiteText{
        color: black !important;
        
    }
}
.logoimg{
    width: 60%;
	margin-bottom: 1rem;
}

.box {
    box-shadow: 0px 1.2px 2px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(20, 19, 36, 0.1);
    border-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    margin-bottom: 20px;

    .login-header{
        padding: 0;
        margin: 1rem 0 0 1rem;

        > h1 {
            letter-spacing: 0.04em;
            line-height: 23px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 28px;
        }
    }

    .box-header {
        padding: 16px 14px calc(16px - 0.111rem) 14px;
        color: #3e3d4b;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        > h1 {
            letter-spacing: 0.04em;
            line-height: 23px;
            font-weight: 500;
            //font-variant: normal;
            text-transform: uppercase;
            font-size: $fontSize;
            //color: inherit;
        }

    }
    .header-text{
        letter-spacing: 0.04em;
            line-height: 23px;
            font-weight: 500;
            color: #3e3d4b;
            text-transform: uppercase;
            font-size: $fontSize;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
    }
}
.errorTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 14px;
}
// password reminder
.backLogin{
    outline: none !important;
    border: none;
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    //padding: 6px 3px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 11px; 
}

.headerBelowLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    > h1 {
        font-size: 20px !important;
    }
}

.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}
.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    p{
        margin-top: 0.5rem;
    }
}
.footer_column {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%; 
    justify-content: center; 
    flex-direction: column; 
    gap: 1rem;
    p{
        margin-top: 0.5rem;
    }
}

.pwdResetBtn{
    width: 100%;
}


// ----



.wrapper-body .container {
    width: 100%;
}

.login-body form, .login-body .form {
  width: 100%;
  padding: 20px;
}

.login-body .box {
    margin-top: 30px;

    .help-block {
        padding-bottom: 15px;
    }

    .has-error .help-block {
        padding-bottom: 0;
    }

    .footer {
        min-height: 60px;
        line-height: 39px;
    }
}

/// Toggle Styles

.remember-checkbox {
    margin-bottom: -8px;

    & label, & input {
        height: 25px !important;
        display: inline-block;
        vertical-align: middle;
    }
}

.login-body .container > .row:first-child {
    @include mq($from: xwide) {
        max-width: $mqLargeDesktopWidth;
        margin: 0 auto;
    }
}
.mobilLogo {
  display: none;
}

@media (max-width: 768px) {
  .mobilLogo{
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    img {
      width: 50%;
    }
  }
  .box-wrapper {
      margin-top: 0;
  }
  .login-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .remember-forgot {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
    gap: 1rem;

    .checkbox {
      display: flex !important;
      align-items: center !important;
    }

    label {
      display: flex !important;
      align-items: center !important;
    }
    
    .form-group {
      height: auto;
      justify-content: center !important;
    }
  }
  .login-wrapper {
    margin: 1rem 1rem 0 1rem !important;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .product-info {
    display: none !important;
  }
  .login-form {
    width: 100% !important;
  }
  .product-info {
    width: 100% !important;
  }
}

@media (min-width: 1600px) {
  .smaller-size {
    font-size: 0.75rem;
  }
}

/* reseller */

.reseller-logo {
  width: 40% !important;
}
