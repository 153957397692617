
$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 5px !default;

.chip {
    display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,.6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $chip-bg-color;
    margin-bottom: $chip-margin;
    margin-right: $chip-margin;

    img {
        float: left;
        margin: 0 8px 0 -12px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }

    .close {
        cursor: pointer;
        float: right;
        font-size: 13px;
        font-weight: 300;
        line-height: 32px;
        padding-left: 8px;
    }
}

.chips {
    border: none;
    border-bottom: 1px solid $chip-border-color;
    box-shadow: none;
    margin: 0 0 20px 0;
    min-height: 45px;
    outline: none;
    transition: all .3s;

    &.focus {
        border-bottom: 1px solid $chip-selected-color;
        box-shadow: 0 1px 0 0 $chip-selected-color;
    }

    &:hover {
        cursor: text;
    }

    .chip.selected {
        background-color: $chip-selected-color;
        color: #fff;
    }

    .input {
        background: none;
        border: 0;
        color: rgba(0,0,0,.6);
        display: inline-block;
        font-size: 1rem;
        height: 3rem;
        line-height: 32px;
        outline: 0;
        margin: 0;
        padding: 0 !important;
        width: 120px !important;
    }

    .input:focus {
        border: 0 !important;
        box-shadow: none !important;
    }

    // Autocomplete
    .autocomplete-content {
        margin-top: 0;
    }
}

// Form prefix
.prefix ~ .chips {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
}
.chips:empty ~ label  {
    font-size: 0.8rem;
    transform: translateY(-140%);
}