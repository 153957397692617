$gap: 1;
$value: 0;
$maxValue: 30;

@while $value <= $maxValue {

    /// Margins

    .m#{$value} {
        margin: #{$value}px !important;
    }

    .mt#{$value} {
        margin-top: #{$value}px !important;
    }

    .ml#{$value} {
        margin-left: #{$value}px !important;
    }

    .mb#{$value} {
        margin-bottom: #{$value}px !important;
    }

    .mr#{$value} {
        margin-right: #{$value}px !important;
    }

    .m#{-$value} {
        margin: #{-$value}px !important;
    }

    .mt#{-$value} {
        margin-top: #{-$value}px !important;
    }

    .ml#{-$value} {
        margin-left: #{-$value}px !important;
    }

    .mb#{-$value} {
        margin-bottom: #{-$value}px !important;
    }

    .mr#{-$value} {
        margin-right: #{-$value}px !important;
    }

    /// // Margins



    /// Paddings

    .p#{$value} {
        padding: #{$value}px !important;
    }

    .pt#{$value} {
        padding-top: #{$value}px !important;
    }

    .pl#{$value} {
        padding-left: #{$value}px !important;
    }

    .pb#{$value} {
        padding-bottom: #{$value}px !important;
    }

    .pr#{$value} {
        padding-right: #{$value}px !important;
    }

    /// // Paddings

    $value: $value + $gap;
}

.mCenter {
    margin: 0 auto !important;
}

.textCenter {
    text-align: center !important;
}

.hideContent {
    display: none;
}

.hideContentForce {
    display: none !important;
}

.textBlock {
    display: block !important;
}

.textInline {
    display: inline !important;
}

.inline-block {
    display: inline-block !important;
}

.oHidden {
    overflow: hidden !important;
}

.v-middle {
    vertical-align: middle !important;
}

.v-top {
    vertical-align: top !important;
}

.v-bottom {
    vertical-align: bottom !important;
}

.text-bold {
    font-weight: 600;
}

.text-sm {
    font-size: 12px;
}

.text-xs {
    font-size: 10px !important;
}

@mixin user-select($select) {
    -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
          user-select: $select;
}

@mixin color-variant($color: #555, $lt-percent: 10%, $lter-percent: 15%, $dk-percent: 10%, $dker-percent: 15%) {
    background-color: $color;

    &.lt,
    .lt {
        background-color: lighten($color, $lt-percent);
    }

    &.lter,
    .lter {
        background-color: lighten($color, $lter-percent);
    }

    &.dk,
    .dk {
        background-color: darken($color, $dk-percent);
    }

    &.dker,
    .dker {
        background-color: darken($color, $dker-percent);
    }
}

@mixin font-variant($color: #555, $hover-color: #fff, $nav-link-percent: 40%, $nav-sublink-percent: 10%, $link-percent: 30%, $text-percent: 40%){
    color: lighten($color, $text-percent);

    a {
        color: lighten($color, $link-percent);
        &:hover {
            color: $hover-color;
        }

        &.list-group-item {
            &:hover,
            &:focus {
                background-color: inherit;
            }
        }
    }

    .nav {

        li {
            > a {
                color: darken($color, $nav-link-percent);
                &:hover {
                    background-color: darken($color, 5%);
                }
            }

            > ul.dropdown-menu > li {
                > a {
                    color: lighten($color, $nav-sublink-percent);
                    &:hover {
                        color: $hover-color;
                    }
                }
            }
        }

        .open > a {
            background-color: darken($color, 5%);
        }
    }

    &.navbar .nav {
        > li.active > a {
            color: $hover-color;
            background-color: darken($color, 5%);
        }
    }

    .open > a {
        &,
        &:hover,
        &:focus {
            color: $hover-color;
        }
    }

    .text-muted {
        color: lighten($color, $text-percent - 5%);
    }
}

