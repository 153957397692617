.addons {
  margin: 20px;
  @media (max-width: $screen-xs-min) {
    margin-left: 0px;
    margin-right: 0px;
  }
  span.super-index {
    font-size: 10pt;
    vertical-align: super;
  }
}

.addons .addon {
  border: 1px solid;
  margin-bottom: 5px;
  display: table;
  padding: 10px;
  width: 100%;

  .first-col, .second-col, .third-col {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

.addons .addon .first-col {
  width: 90px;
  margin-left: 5px;

  img {
    max-width: 60px;
    margin-left: 5px;
    text-align: center;
  }
}

.addons .addon .third-col {
  text-align: center;
}