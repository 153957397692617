#resendConfEmailButton {
    display: block;
    margin: 10px auto 0px auto;
}

.inline-blocks {

  padding-top: 10px;

  .calling-code-dropdown {
    width: 100%;
  }

  .phone-input {
    width: 280px;
  }

  .form-group {
    display: inline-block;

    .col-sm-9 {
      display: inline-block;
      padding-top: 10px;
    }
  }
}

#pswd-req {
    .uncomplete {
        line-height:24px;
        color:#202020;
    }

    .completed {
        line-height:24px;
        color: #d6d6d6;
    }

    ul {
        list-style: none;
        margin-left: 5px;
        padding-left: 10px;
        font-size: 12px;
        line-height: 24px;
    }
    
    ul li::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        border-radius: 6px;
        background: #c42026;
    }
    
    ul li {
        -ms-transition: all .25s ease-in-out 0s;
        transition: all .25s ease-in-out 0s;
    }
    
    ul li.completed::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        border-radius: 6px;
        background: #d6d6d6;
    }
}