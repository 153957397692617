// Wrappers

#wrapper {
  width: 100%;
}

@media(min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 140px;

    &.with-console-header{
      margin: 0;
    }
  }
}

// Navigation

// --Topbar

.navbar, .navbar-header {
  background-color: #ffffff !important;
}

.navbar-brand {
  padding: 7px 15px !important;
}

.navbar-header {
  & .logo {
    img {
      height: 37px;
      position: relative;
    }
  }
}

.navbar-top-links {
  margin-right: 0;

  & > li {
    display: block;
    float: left;
    border-left: 1px solid #e7e7e7;

    & a {
      padding: 15px;
      min-height: $header-height;

      &, &:focus {
        background-color: transparent;
      }

      &:hover, &:active {
        background-color: $nav-link-hover-bg;
      }
    }
  }

  & li:last-child {
    margin-right: 15px;
  }

  & .dropdown a.dropdown-toggle span {
    padding-left: 5px;
    padding-right: 10px;
  }

  & .dropdown-menu li {
    display: block;
  }

  & .dropdown-menu li:last-child {
    margin-right: 0;
  }

  & .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
  }

  & .dropdown-menu li a div {
    white-space: normal;
  }

  & .dropdown-messages,
  & .dropdown-tasks,
  & .dropdown-alerts {
    width: 310px;
    min-width: 0;
  }

  & .dropdown-user {
    width: 146px;
    min-width: 0;

    & li {
      & a {
        padding: 10px 20px;

        & i {
          margin-right: 5px;
        }
      }
    }
  }

  & .dropdown-messages {
    margin-left: 5px;
  }

  & .dropdown-tasks {
    margin-left: -59px;
  }

  & .dropdown-alerts {
    margin-left: -123px;
  }

  & .dropdown-user {
    right: 0;
    left: auto;
  }
}

// --Topbar

.top-search-bar {
  width: 100%;
  height: 50px;
  position: fixed;
  top: -100px;
  z-index: 1050;
  background-color: #ffffff;
  @include transition(all 350ms cubic-bezier(0.19, 1, 0.22, 1));
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &.search-bar-toggle {
    top: 0;
  }

}

// --Sidebar

.left-sidebar {
  background-color: $menuBgColor;
  height: 100%;
  width: 150px !important;

  .sidebar-nav.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
  }

  ul li {
    border-bottom: none !important;

    a {
      padding-left: 37px;
      color: #fff;

      &.active {
        background-color: $gray-lighter;
      }
    }
  }
}

@media(max-width: 767px) {

  .navbar {
    border: none;
  }

  .mobile {
    .navbar-header {
      border-bottom-color: $menuBgColor !important;
      background: #fff !important;
    }

    .navbar-collapse {
      border-color: $menuHoverColor;
    }

    .navbar-top-links {
      background: $menuBgColor;

      .dropdown {
        display: block;

        & a.dropdown-toggle {

          color: $menuTextColor;
          display: block !important;
          width: 100% !important;

          &:hover, &:active, &:focus {
            background-color: $menuHoverColor;
          }

          & span {
            padding-left: 15px;
            padding-right: 15px;
          }

          & i.fa:last-child {

            float: right;

            &:before {
              content: "\f107" !important;
            }

          }

        }

        & a.dropdown-toggle[aria-expanded='true'] i.fa:last-child:before {
          content: "\f106" !important;
        }

        & .dropdown-menu {
          width: 100%;
          margin-left: 0;
          position: relative;
          float: none;

          & > li {
            display: block;
          }
        }

      }

      & li:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

@media(min-width: 768px) {
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

// Buttons

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: $brand-primary;
}

.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: white;
}

pre {
  border-radius: 0;
  background: lighten($brand-default, 30%);
  border: none;
}

.bg-success {
  @include color-variant($brand-success, 3.5%, 7.5%, 3.5%, 7%);
  @include font-variant($brand-success, #fff, 35%, 20%, 45%, 40%);
}

.bg-info {
  @include color-variant($brand-info, 3.5%, 7.5%, 3.5%, 7%);
  @include font-variant($brand-info, #fff, 35%, 20%, 45%, 40%);
}

.bg-warning {
  @include color-variant($brand-warning, 3.5%, 7.5%, 3.5%, 7%);
  @include font-variant($brand-warning, #fff, 35%, 20%, 45%, 40%);
}

.bg-danger {
  @include color-variant($brand-danger, 3.5%, 7.5%, 3.5%, 7%);
  @include font-variant($brand-danger, #fff, 35%, 20%, 45%, 40%);
}

//  Extra grid system properties

@media (min-width: 768px) {
  .col-sm-text-left {
    text-align: left;
  }
  .col-sm-text-right {
    text-align: right;
  }
  .col-sm-text-center {
    text-align: center;
  }

}@media (max-width: 576px) {
  .col-xs-text-left {
    text-align: left;
  }
  .col-xs-text-right {
    text-align: right;
  }
  .col-xs-text-center {
    text-align: center;
  }
}

.next-payments-table tr td {
  word-break: break-word;
}

// Global notification system

  .notification-badge-counter {
    position: absolute;
    -webkit-transform: scale(.7);
    transform: scale(.7);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    right: 7px;
    margin-top: -25px;
  }

  .notification-badge-danger {
    color: #fff;
    background-color: #c42026;
  }

  .notification-badge {
    display: inline-block;
    padding: 5px 0px;
    font-size: 100%;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .35rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  }

  @media (min-width: 576px) {
    .dropdown .dropdown-menu {
        width: auto;
        right: 0;
    }
  }
  
  .dropdown .dropdown-menu {
      width: 300px;
  }
  .dropdown-menu {
      position: absolute;
  }
  @media (min-width: 576px) {
    .topbar .dropdown-list {
        width: 20rem!important;
    }
  }
  .dropdown-list {
      padding: 0;
      border: none;
      overflow: hidden;
  }
  .dropdown .dropdown-menu {
      font-size: .85rem;
  }
  .dropdown-menu {
      position: static;
      float: none;
  }
  .dropdown-menu.show {
      display: block;
  }
  
  .shadow {
      -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
      box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
  }
  .dropdown-menu-right {
      right: 0;
      left: auto;
  }
  .dropdown-menu {
      position: absolute;
      top: 100%;
      z-index: 1000;
      display: none;
      float: left;
      margin: .125rem 0 0;
      font-size: .85rem;
      color: #858796;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
  }

  .dropdown-header {
    background-color: #4da7e8;
    border: 1px solid #4da7e8;
  }

  .dropdown .dropdown-menu {
    font-weight: 800;
    font-size: .65rem;
    color: #b7b9cc;
  }

  .dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 15px;
    color: #fff;
    white-space: nowrap;
  }

  .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
    text-decoration: none;
  }

  .align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
  }

  .d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #3a3b45;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .text-gray-500 {
    color: #b7b9cc!important;
  }

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  .icon-circle {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
  }

  .notification-unread {
    font-weight: 700 !important;
  }

  .text-center {
      text-align: center!important;
  }