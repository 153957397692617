table.yiiLog {
    $logPadding: 10px;
    overflow: scroll;

    body.mobile > & {
        display: none;
    }

    @include mq($from: tablet) {
        tr > td:first-child, tr > th:first-child {
            padding-left: (50px + $logPadding);
        }
    }

    tr > td, tr > th {
        word-wrap: break-word;
        word-break: break-all;
        padding: 0 $logPadding;
        min-width: 50px;

        & > pre {
            margin: $logPadding 0;
            width: auto !important;

            @include mq($until: mobile) {
                max-width: ($mqMobileWidth - 200px);
            }

            @include mq($from: mobile, $until: tablet) {
                max-width: ($mqMobileWidth - 200px);
            }

            @include mq($from: tablet, $until: desktop) {
                max-width: ($mqTabletWidth - 200px);
            }
        }
    }
}

#ajaxLoader, .ajaxLoader {
    display: none;
    width: 16px;
    height: 16px;
    float: right;
    margin-left: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../img/ajax-loader.gif);

    &.big {
        width: 22px;
        height: 22px;
    }
}

$ajaxLoaderWidth: 130px;

#globalAjaxLoader {
    display: none;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    font-weight: bold;
    width: $ajaxLoaderWidth;
    margin: 0;
    margin-left: -($ajaxLoaderWidth/2);
    background-color: $brand-danger;
    color: #fff;
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 9999;

    .login-body & {
        top: 0;
    }
}

.main-container > .container {
    min-height: calc(100vh - 100px);
}

.page-header {
    margin-top: 20px;
}

.footer-wrapper {
    line-height: 49px;
    background: #fff;
    border-top: 1px solid #d9d9d9;
}

.footer-content {
    font-size: 0.75rem;
    margin: 0;
}

h3 + table {
    margin-top: 20px;
}

.help-btn {
    margin-left: 5px !important;
}

.nav-tabs, .nav-pills {
    border-bottom-width: 0;

    li {
        @include mq($until: desktop) {

            > a {
                text-align: center;
            }
        }

        > a, > a:hover {
            border: none;
            border-bottom: 3px solid transparent;
        }

        &.active {

            > a {
                color: $textColor;
                background-color: transparent;

                &, &:hover {
                    border: none;
                    border-bottom: 3px solid $brand-primary;
                }
            }

            li {
                border-radius: 50%;

                @include mq($until: desktop) {
                    width: 32%;
                }
            }
        }
    }
}

.no-labels {
    .control-label {
        display: none;
    }
}

.table-mid-height {
    td {
        height: 70px;
        vertical-align: middle !important;

        & > div, & [class*='col-'] {
            padding: 0;
            margin: 0;
        }
    }
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: -160px;
    margin-top: -6px;
}

ul.nav li.dropdown:hover > ul.dropdown-user {
    display: block;
    margin-top: 0px;
}
ul.nav li.dropdown-submenu:hover > ul.dropdown-menu {
    display: block;
    margin-top: 0px;
    top: 0;
    left: -160px;
}

figure.highlight {
    padding: 12px 15px;
    margin-bottom: 14px;
    position: relative;
    background-color: #282C37;
    border: rgba(0,0,0,.1);
    border-radius: 4px;

    &:not(.no-decorate) {
        padding-left: 30px;

        &:before {
            position: absolute;
            content: '#';
            color: #ccc;
            top: 10px;
            left: 15px;
        }
    }

    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        word-break: normal;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        code {
            color: #ccc;
        }
    }
}

.fa.log-file-format{
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../img/log-file-format.svg);
}

.bnvl-label{
    padding: 4px 8px;
    background-color: #999999;
    font-weight: bold;
    font-size: 8pt;
    color: white;
    border-radius: 10px;
    display: inline-block;
}

.bnvl-label:hover,.bnvl-label:focus{
    color: white;
}

.btn-create-group {
    height: 60px !important;
    padding-top: 10px !important;
    margin: 5px 10px 5px 0px;
    border-radius: 5px !important;
    border-radius: 0px;
    border: 2px solid #dcdcdc !important;
    background-color: #f1f1f1;
    color: #202020;
}