@import "../bootstrap/variables";
@import "../bootstrap/mixins";

.step {

    .steps {
        position: relative;
        padding: 15px;
        text-align: center;
        z-index: 10;
        @include transition(all .3s);

        .step-number {
            width: 40px;
            height: 40px;
            display: inline-block;
            line-height: 40px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background-color: #eee;
            color: #666;
            position: relative;
            z-index: 6;
        }

        p {
            color: #444;
            font-size: 14px;
            padding: 10px 0 0 0;
            margin-bottom: 0;
        }

        &:before {
            content: "";
            height: 3px;
            background-color: #eee;
            width: 60%;
            position: absolute;
            top: 34px;
            left: -30px;
        }

        &:after{
            content: "";
            height: 3px;
            background-color: #eee;
            width: 50%;
            position: absolute;
            top: 34px;
        }
    }

    &:first-child .steps:before {
        display: none !important;
    }

    &:last-child .steps:after {
        display: none !important;
    }

    &.active-step {

        .steps {

            .step-number {
                background: $brand-danger;
                color: #fff;
            }
        }

    }
}