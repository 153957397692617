.counter {
    color: #c42026;
    font-weight: bold;
}

@media (max-width: 840px) {
    .incident-counter {
        padding-top: 20px;
        font-size: 20px;
    }
    
    .counter {
        font-size: 20px;
    }
}