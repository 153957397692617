@import "../bootstrap/mixins";

//
// Stripe loading indicator
// --------------------------------------------------

body.loading, body.loading * {
    cursor: wait !important;
}

$stripe-loader-color: #0090c0;
$stripe-loader-height: 5px;

.stripe-loading-indicator {

    height: $stripe-loader-height;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 2000;

    .stripe, .stripe-loaded {
        height: $stripe-loader-height;
        display: block;
        background: $stripe-loader-color;
        position: absolute;
        @include box-shadow(inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF);
    }

    .stripe {
        width: 100%;
        @include animation(ui-infinite-loader 60s linear);
    }

    .stripe-loaded {
        width: 0;
        @include opacity(0);
    }

    &.loaded {
        @include opacity(0);
        @include transition(opacity .4s linear);
        @include transition-delay(.3s);
        .stripe-loaded {
            @include opacity(1);
            @include transition(width .3s linear);
            width: 100% !important;
        }
    }

    &.hide {
        display: none;
    }
}

@-moz-keyframes ui-infinite-loader {
    0% {
        width: 0;
    }
    10% {
        width: 42%;
    }
    20% {
        width: 63%;
    }
    30% {
        width: 78.75%;
    }
    40% {
        width: 88.59375%;
    }
    50% {
        width: 94.130859375%;
    }
    60% {
        width: 97.07244873046875%;
    }
    70% {
        width: 98.58920574188232%;
    }
    80% {
        width: 99.35943391174078%;
    }
    90% {
        width: 99.74755670045852%;
    }
    100% {
        width: 99.9423761471391%;
    }
}

@-webkit-keyframes ui-infinite-loader {
    0% {
        width: 0;
    }
    10% {
        width: 42%;
    }
    20% {
        width: 63%;
    }
    30% {
        width: 78.75%;
    }
    40% {
        width: 88.59375%;
    }
    50% {
        width: 94.130859375%;
    }
    60% {
        width: 97.07244873046875%;
    }
    70% {
        width: 98.58920574188232%;
    }
    80% {
        width: 99.35943391174078%;
    }
    90% {
        width: 99.74755670045852%;
    }
    100% {
        width: 99.9423761471391%;
    }
}

@-o-keyframes ui-infinite-loader {
    0% {
        width: 0;
    }
    10% {
        width: 42%;
    }
    20% {
        width: 63%;
    }
    30% {
        width: 78.75%;
    }
    40% {
        width: 88.59375%;
    }
    50% {
        width: 94.130859375%;
    }
    60% {
        width: 97.07244873046875%;
    }
    70% {
        width: 98.58920574188232%;
    }
    80% {
        width: 99.35943391174078%;
    }
    90% {
        width: 99.74755670045852%;
    }
    100% {
        width: 99.9423761471391%;
    }
}

@-ms-keyframes ui-infinite-loader {
    0% {
        width: 0;
    }
    10% {
        width: 42%;
    }
    20% {
        width: 63%;
    }
    30% {
        width: 78.75%;
    }
    40% {
        width: 88.59375%;
    }
    50% {
        width: 94.130859375%;
    }
    60% {
        width: 97.07244873046875%;
    }
    70% {
        width: 98.58920574188232%;
    }
    80% {
        width: 99.35943391174078%;
    }
    90% {
        width: 99.74755670045852%;
    }
    100% {
        width: 99.9423761471391%;
    }
}

@keyframes ui-infinite-loader {
    0% {
        width: 0;
    }
    10% {
        width: 42%;
    }
    20% {
        width: 63%;
    }
    30% {
        width: 78.75%;
    }
    40% {
        width: 88.59375%;
    }
    50% {
        width: 94.130859375%;
    }
    60% {
        width: 97.07244873046875%;
    }
    70% {
        width: 98.58920574188232%;
    }
    80% {
        width: 99.35943391174078%;
    }
    90% {
        width: 99.74755670045852%;
    }
    100% {
        width: 99.9423761471391%;
    }
}