$logoWidth:      40px;
$smallLogoWidth: 70px;
$buttonWidth:    60px;
$backBtnWidth:   30px;
$smallBtnWidth:  32px;

.nav.navbar-right td:first-child {

    @include mq($until: tablet) {
        width: ($logoWidth + 10px);
        text-align: left;
    }

    @include mq($from: tablet) {
        display: none;
    }

}

.mobile-ip {

    @include mq($until: tablet) {

        .navbar-header, .navbar-top-links, .navbar.navbar-fixed-top > div {
            display: none;
        }

        .navbar.navbar-fixed-top > div.ip-input {
            display: block;
            height: 51px;
            border-bottom: 1px solid $menuBgColor;
            width: 100%;
            position: fixed;
            top: 0;
            background-color: $menuBgColor;
            max-width: 100% !important;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 9px;

            & .input-group-btn-back {
                width: $backBtnWidth !important;
                display: block;
                text-align: center !important;
                font-weight: bold;
                font-size: 20px;
                color: #fff;
                border-right: 1px solid lighten($menuBgColor, 30%);
                padding-right: 10px;
            }

            & .input-group-btn .btn {
                width: $smallBtnWidth !important;
            }

            & #criteria {
                width: 100% !important;
            }
        }

        #searchSelect {
            width: (3 * $smallBtnWidth) !important;
        }

        .nav.navbar-right table {
            width: 100%;
        }

        .nav.navbar-right td:last-child {
            width: ($buttonWidth * 2 + 20);
        }

    }

}

@media screen and (min-width: 992px) {

    .adv-search {
        width: 500px !important;
    }
}

@media screen and (min-width: 768px) {

    header.navbar {
        display: flex;
        height: 50px;
        
        
        .flex-1 {
            flex: 1;
            
            > li {
                float: right;
                 padding: 8px;
            }
        }

        .adv-search {
            width: 325px;

            .btn {
                padding: 6px 12px;
            }

            .btn-group {
                display: flex;

                > .btn {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            input[type="text"] {
                border-width: 1px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }

}