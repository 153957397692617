@import "../../utils/_mq";

.creditCardAlertWrapper {
    max-width: 500px;

    > h2 {
        margin: 20px auto 0 auto;
        text-align: center;
    }

    & h2 + div {
        > p {
            text-align: justify;
            padding-bottom: 10px;
            font-size: 16px;
        }
    }

    div.row {
        padding-top: 30px;
        line-height: 40px;

        @include mq($until: desktop) {
            line-height: 50px !important;
        }

        & > div {
            @include mq($until: desktop) {
                text-align: center !important;

                & .btn {
                    display: inline-block;
                    width: 45%;

                    &:first-child {
                        float: left;
                    }

                    &:last-child {
                        float: right;
                    }
                }

                & .checkbox {
                    margin-bottom: 20px;
                }
            }
        }
    }
}