.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    display:none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-default;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:disabled + .slider {
    background-color: lighten($brand-default, 18%);
}

input:checked + .slider {
    background-color: darken($brand-success, 0%);
}

input:checked:disabled + .slider {
    background-color: lighten($brand-success, 18%);
}

input:checked + .slider:before {
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 16px;
}

.slider.round:before {
    border-radius: 50%;
}