// Variables
$gray-darker: lighten(#000, 13.5%);
$gray-dark: lighten(#000, 20%);
$gray: lighten(#000, 33.5%);
$gray-light: lighten(#000, 60%);
$gray-lighter: lighten(#000, 93.5%);
$gray-lightest: lighten(#000, 97.25%);
$brand-default: #999999;
$brand-primary: #428bca;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #C42026;
$header-height: 50px;

