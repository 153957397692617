.globally-listed {
    border-radius: 3px;
    background: #fbfbfb !important;
    border-left: 10px solid #c42026;
    margin: 1px;
    padding: 10px;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

#severity {
    .fa {
        font-size: 1.5em;
    }
    .fa-question {
        font-size: 0.9em;
        padding-top: 6px;
        padding-right: 6px;
    }

    .fa-minus {
        font-size: 0.9em;
        padding-top: 7px;
        padding-right: 4px;
    }

    .fa-angle-up
    .fa-angle-down
    .fa-angel-double-up
    .fa-angle-double-down {
        padding-top: 2px;
    }
}

// Aliases for severity icons

    .severity-icon-highest {
        @extend .fa, .fa-angle-double-up,
    }

    .severity-icon-high {
        @extend .fa, .fa-angle-up,
    }

    .severity-icon-normal {
        @extend .fa, .fa-minus,
    }

    .severity-icon-low {
        @extend .fa, .fa-angle-down,
    }

    .severity-icon-lowest {
        @extend .fa, .fa-angle-double-down,
    }

    .severity-icon-unknown {
        @extend .fa, .fa-question,
    }

.severity-badge {
    display: inline-block;
    text-transform: uppercase;
    font-size: 8pt;
    min-width: 10px;
    padding: 3px 10px;
    background-color: #fdfdfd;
    vertical-align: middle;
    border-radius: 4px;
    border: 1px solid;
    border-bottom: 3px solid;
}

.severity-highest {
    color: #c42026;
    border-color: #c42026;
}

.severity-high {
    color: #ff4d00;
    border-color: #ff4d00;
}

.severity-normal {
    color: #ff7400;
    border-color: #ff7400;
}

.severity-low {
    color: #ff9a00;
    border-color: #ff9a00;
}

.severity-lowest {
    color: #ffc400;
    border-color: #ffc400;
}

.severity-unknown {
    color: silver;
    border-color: silver;
}

@media only screen and (max-width: 1440px) {
    .severity-badge {
        font-size: 6pt;
        display: none;
        float: right;
    }

    .bnvl-label {
        display: hidden !important;
    }

    td#severity {
        width: 1%;
    }
}