$creditCard_firstPartMinWidth: 175px;
$creditCard_expYearWidth: 90px;

#creditCardForm, #invoiceDataForm, #ninjaContactForm, #settingsContent {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .first-part {
        @include mq($until: tablet) {
            padding-top: 21px !important;
        }
        @include mq($from: tablet) {
            padding-top: 25px !important;
        }
    }

    label[for='paypal-container'] {
        padding-top: 7px !important;
    }
}

#ninjaContactForm {
    .contact-captcha {
        padding-top: 0;
        margin-top: -5px;
    }
}

#settingsContent {
    .second-part {
        @include mq($until: tablet) {
            padding-top: 21px !important;
        }
        @include mq($from: tablet) {
            padding-top: 25px !important;
        }
        
        
        > .form-group {
            margin-top: -10px;
            margin-bottom: 8px;
            
            & + .form-group {
                margin-top: 10px;
            }
        }    
    }    
    
    form {
        .second-part {
            @include mq($until: tablet) {
                padding-top: 25px !important;
            }    
        }
    }
}

.login-body {
    #ninjaContactForm {
        .first-part, .second-part, .third-part {
            padding: 4px 8px 12px 8px;
        }
        .contact-captcha {
            padding-top: 5px;
            margin-top: 0;
        }
    }   
    
    #regAdditionDatas-form {
        .first-part, .second-part, .third-part {
            padding: 4px 8px 12px 8px;
        }
        
        .first-part {
            padding-top: 25px;
            
            @include mq($until: tablet) {
                padding-top: 21px;
            }
        }
    }
}

#cc-number, #cc-cvv, #cc-exp-month, #cc-exp-year{
    padding-bottom: 6px;
    border-radius: 5px;
    border-color: #cccccc !important;
    width: 100%;
    border-width: 1px !important;
    padding-bottom: 6px;
    border-color: #a2a1a1 !important;
    -webkit-appearance: textarea;
    background-color: white;
    -webkit-rtl-ordering: logical;
    flex-direction: column;
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border-style: solid;
    border-color: initial;
    -o-border-image: initial;
    border-image: initial;
    padding: 8px;
}

#cc-exp-month, #cc-exp-year,#cc-exp-month-error, #cc-exp-year-error {
    display: inline-block;
    width: calc(50% - 10px);
}

#cc-exp-year + .error {
    display: block !important;
    clear: both !important;
    padding-top: 5px;
}

#creditCardIcons {
    @include mq($until: tablet) {
        text-align: center;
    }

    line-height: 50px;
    margin-top: 20px;
    
    img { 
       height: 35px;
       cursor: auto;
    }
}

#finish-button,
#step-2-button,
#step-1-button {
    background-color: #2091e2;
    border-color: #197dc5;
}
.payment-method-container {
    margin-bottom: 20px;

    h3 {
        margin-top: 10px;
    }
}

#paypal-button {
    margin: 50px auto;
    width: 200px;
    height: 30px;
}

@media (min-width: 768px) {
    .payment-method-container {
        
        .col-sm-8 .group-rom {
            margin-right: 30px;
            width: calc(100% - 30px);
        }
        
        span.or {
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 100%;
            border: 1px solid #ddd;
            background: #fff;
            text-align: center;
            top: calc(50% - 20px);
            right: -20px;
            font-size: 12px;
            z-index: 20;
        }
        
        & > div:first-child {
            border-right: 1px solid #ddd;
        }
    }
}

.setup-content .sweet-alert,
.setup-content .sweet-alert-2
{
    width: auto;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    position: static;
    left: 15px;
    right: 15px;
}

@media (max-width: 768px) {
    .payment-method-container {
        
        span.or {
            display: none;
        }
    }
}

.nav-pills-bn > li {
    float: left;
    margin: 9px 0 9px 0;
}

.nav-pills-bn > li > a {
    line-height: 1.428571429;
    color: #8c8c8c;
    font-weight: bold;
}

.nav-pills-bn > li.active > a, .nav-pills-bn > li.active > a:hover, .nav-pills-bn > li.active > a:focus {
    color: #3479b7;
    cursor: default;
    background-color: white;
}

.nav-pills-bn > li {
    float: left;
}

.paypal-loggedin{
    display: none;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    font-size: 12pt;
}

.paypal-loggedin #logo{
    color: #009cde;
}

.paypal-loggedin #email{
    font-weight: bold;
}

.payment-method-container {

    select.form-control, textarea.form-control {
        border-width: 1px!important;
        border-radius: 5px;
        border-color: #cccccc!important;
        
    }

    input.form-control, input[type=email], input[type=password], input[type=text] {
        border-width: 1px;
        padding-bottom: 6px;
        border-radius: 5px;
        border-color: #cccccc!important;
        width: 100%;
    }

    @media (max-width: 768px) {
        input.form-control, input[type=email], input[type=password], input[type=text] {
            width: 100%;
        }
        #cc-exp-month, #cc-exp-year, #cc-exp-month-error, #cc-exp-year-error {
            display: inline-block;
            width: calc(50% - 10px);
        }
    }
}