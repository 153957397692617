
.license-modal {
  .title {
    text-align: center;
    font-size: 1.3rem;
    color: #666;
    font-weight: bold;
    padding-top: 15px;
  }

  .close {
    top: 20px;
    right: 20px;
    position: relative;
  }
}

.vps-packages-page {

  .header-question {
    padding: 10px;
    font-size: 1rem;

    a {
      font-weight: bold;
      color: #1B83CE;
    }
  }

  // COMPONENTS

  // VPS Licenses
  .vps-licenses {

    h1 {
      padding-bottom: 16px;
    }

    padding: 20px 0;

    .license-list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  // VPS License
  .vps-license {
    text-align: center;
    padding: 30px 10px;
    background-color: white;
    margin: 0 7px;
    flex: 1 1 0;
    font-size: 0.8rem;

    box-shadow: 0 0 16px -1px rgba(0, 0, 0, 0.10);

    .name {
      font-size: 1.3rem;
      color: #666;
      font-weight: bold;
    }

    .monthly {
      font-weight: bold;
      color: #999;
      padding-bottom: 15px;
    }

    .icon {
      background-repeat: no-repeat;
      background-position: center center;
      height: 100px;
      width: 100px;
      padding: 10px 0;
      margin: auto;
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        .icon {
          background-image: url("../img/vps-licence/licence_#{$i}.png");
        }
      }
    }

    .custom-pricing {
      font-size: 1.5rem;
      font-weight: bold;
      padding: 20px 0;
      line-height: 100%;
      text-align: center;
    }

    .price {
      font-size: 2rem;
      color: #C62128;
      font-weight: bold;
      line-height: 0.86;
      padding-top: 20px;

      &:before {
        content: "$";
      }
    }

    .monthly-price {
      padding: 10px 0;
    }
  }


  // FAQ

  .vps-faq {
    .faq-element {
      background-color: white;
      margin: 10px 0;
      box-shadow: 0 0 16px -1px rgba(0, 0, 0, 0.10);

      .title {
        padding: 15px;
        font-weight: bold;
        font-size: 0.9rem;
        cursor: pointer;
        // background-color: #ececec;
      }

      .body {
        padding: 10px;
      }
    }
  }

  // VPS confirm modal


  #vpsConfirmModal {
    @extend .license-modal;

    .charge-info {
      font-size: 0.8rem;
      text-align: center;
      padding: 20px 0;

      a {
        display: block;
        color: #1B83CE;
        text-decoration: underline;
      }
    }
  }

  // PAGES

  .vps-page {
    h1 {
      padding: 20px;
    }

    h3 {
      padding-bottom: 20px;
    }
  }

  // Thank you

  .vps-thank-you {
    @extend .vps-page;

    text-align: center;
    padding-top: 30vh;


    .thank-you-icon {
      width: 100px;
      height: 100px;
      margin: auto;
    }

    .circle {
      opacity: 0;
      transform: scale(0.9);
      transform-origin: center;
      animation: circle-animation 0.5s ease-out forwards;
      animation-delay: 0.8s;
    }

    .checkmark {
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
      transform-origin: center;
      stroke: #cfd8dc;
      animation: checkmark-animation 1s ease-out forwards;
      animation-delay: 1s;
    }

    @keyframes circle-animation {
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes checkmark-animation {
      40% {
        transform: scale(1);
      }
      55% {
        stroke: #cfd8dc;
        transform: scale(1.2);
      }
      70% {
        transform: scale(1);
      }
      100% {
        stroke-dashoffset: 0;
        transform: scale(1.1);
        stroke: #21b587;
      }
    }
  }

  .vps-get-quote {
    @extend .vps-page;
  }
}

.install-license {
  text-decoration: underline;
  color: #3278e3;
  margin: 4px;
  cursor: pointer;
}

.license-deleted {
  background-color: #ffe6e6 !important;
  color: #999 !important;
}

.deleted-servers-btn{
  padding: 10px;
  color:#C62128;
  cursor: pointer;
  font-size: 12px;
}


