.modules-comparison-table::after {
    display: none;
    content: 'mobile';
}

@media only screen and (min-width: 1170px) {
    .modules-comparison-table::after {
        content: 'desktop';
    }
}

.modules-table {
    position: relative;
    overflow: hidden;
    margin: -20px 0 0;
    background: #fff;

    ul {
        padding: 0;
        list-style-type: none;
    }

    .servers {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 120px;
        background-color: #fafafa;
        opacity: .95;
        border: 0;
    }
}

.modules-table .servers::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 4px;
    height: 100%;
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.06), transparent);
    background-image: linear-gradient(to right,rgba(0, 0, 0, 0.06), transparent);
    opacity: 0;
}

@media only screen and (min-width: 1170px) {
    .modules-table .servers {
        width: 211px;
    }
}

.modules-table.scrolling .servers::after {
    opacity: 1;
}

.modules-wrapper {
    overflow-x: hidden;
    border: 0;
}

.modules-columns {
    width: 1200px;
    margin-left: 120px;
    margin-top: 0;
    margin-bottom: 0;
}
.modules-columns::after {
    clear: both;
    content: "";
    display: table;
}

$gap: 1;
$val: 5;
$end: 20;

@media only screen and (min-width: 1170px) {
    .modules-columns {
        margin-left: 210px;
    }

    @while $val <= $end {
        .column-#{$val} {
            width: #{($val * 260)}px;
        }

        $val: $val + $gap;
    }
}

@media only screen and (max-width: 1170px) {

    @while $val <= $end {
        .column-#{$val} {
            width: #{($val * 150)}px;
        }

        $val: $val + $gap;
    }
}

.modules-columns .module {
    position: relative;
    float: left;
    width: 150px;
    text-align: center;
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
}

@media only screen and (min-width: 1170px) {
    .modules-columns .module {
        width: 260px;
    }
}

.server-list li {
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-top-width: 0;
    height: 60px;
}

@media only screen and (min-width: 1170px) {
    .server-list li {
        font-size: 13px;
    }
}

.module .top-info {
    border-right: 0 !important;
    padding-left: 0.625em !important;
    padding-right: 0.625em !important;
    
    small {
        font-size: 75%;
    }
}

.module .server-list li {
    border-right: 0;
}

.servers .server-list {
    width: 210px;
}

.servers .server-list li,
.modules-table .servers .top-info {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    padding: 25px 10px;
    text-align: left;
}

@media only screen and (min-width: 1170px) {
    .servers .server-list li,
    .modules-table .servers .top-info {
        line-height: 16px;
        padding: 15px 20px;
    }
}

.servers .server-list li {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: 0;
    border-left: 0;
}

.modules-table .top-info {
    position: relative;
    width: 150px;
    height: 150px;
    text-align: center;
    padding: 1.25em;
    border: 1px solid #e6e6e6;
    transition: height 0.3s;
    background: #ffffff;
    z-index: 2;
}

.modules-table .top-info::after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    height: 4px;
    width: 100%;
    background-color: transparent;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06), transparent);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.06), transparent);
    opacity: 0;
}

.modules-table .top-info h3 {
    padding: 1.25em 0 0.625em;
    font-weight: bold;
    font-size: 13px;
    margin: 0;
}

@media only screen and (min-width: 1170px) {
    .modules-table .top-info {
        width: 260px;
    }

    .modules-table .top-info h3 {
        padding-top: 1.4em;
        font-size: 16px;
        margin: 0;
    }
}

.modules-table .servers .top-info {
    width: 120px;
    padding-top: 1.25em;
    cursor: auto;
    background: #fafafa;
    border-left: 0;
}

.modules-table .servers .server-list {
    width: 120px;
}

@media only screen and (min-width: 1170px) {
    .modules-table .servers .top-info,
    .modules-table .servers .server-list {
        width: 210px;
    }
}

@-webkit-keyframes cd-bounce {
    0% {
        -webkit-transform: scale(1);
    }
    60% {
        -webkit-transform: scale(1.6);
    }
    100% {
        -webkit-transform: scale(1.5);
    }
}

@-moz-keyframes cd-bounce {
    0% {
        -moz-transform: scale(1);
    }
    60% {
        -moz-transform: scale(1.6);
    }
    100% {
        -moz-transform: scale(1.5);
    }
}

@keyframes cd-bounce {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    60% {
        -webkit-transform: scale(1.6);
        -moz-transform: scale(1.6);
        -ms-transform: scale(1.6);
        -o-transform: scale(1.6);
        transform: scale(1.6);
    }
    100% {
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@media only screen and (min-width: 1170px) {
    .modules-table.top-fixed .modules-columns > li,
    .modules-table.top-scrolling .modules-columns > li,
    .modules-table.top-fixed .servers,
    .modules-table.top-scrolling .servers {
        padding-top: 160px;
    }

    .modules-table.top-fixed .top-info,
    .modules-table.top-scrolling .top-info {
        height: 160px;
        position: fixed;
        top: 0;
    }

    .no-cssgradients .modules-table.top-fixed .top-info, .no-cssgradients
    .modules-table.top-scrolling .top-info {
        border-bottom: 1px solid #e6e6e6;
    }

    .modules-table.top-fixed .top-info::after,
    .modules-table.top-scrolling .top-info::after {
        opacity: 1;
    }

    .modules-table.top-scrolling .top-info {
        position: absolute;
    }
}

.table-navigation {
    margin: 0;
}

.table-navigation a {
    position: absolute;
    top: 0;
    right: 15px;
    transform: translateY(50px);
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
    height: 60px;
    width: 40px;
    background: rgba(64, 64, 66, 0.8) url("../img/arrow.svg") no-repeat center center;
    border-radius: 3px;
    transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
    z-index: 110;
}

.table-navigation a.inactive {
    opacity: 0;
    visibility: hidden;
}

.table-navigation a.prev {
    left: 120px;
    right: auto;
    transform: translateY(50px) translateX(15px) rotate(180deg);
}

.no-touch .table-navigation a:hover {
    background-color: #404042;
}

@media only screen and (min-width: 1170px) {
    .table-navigation a {
        transform: translateY(50px);
    }
    .table-navigation a.prev {
        left: 210px;
    }

    body:not(.toggled) .top-fixed .table-navigation a.next {
        right: 375px;
    }

    .top-fixed .table-navigation a {
        position: fixed;
    }

    .top-fixed .table-navigation a, .top-scrolling .table-navigation a {
        transform: translateY(50px);
    }

    .top-fixed .table-navigation a.prev, .top-scrolling .table-navigation a.prev {
        transform: translateY(50px) translateX(155px) rotate(180deg);
    }
}

/*############################ NINJA LOADER START ###############################*/

$spinning-time: 3s;
$keyframe-name: spin;
$transform-degree: 180deg;

.ninja-spinner-container {
    width: 300px;
    margin: 20px auto;
    display: none;

    .ninja-spinner-outer {
        position: relative;
    
        .ninja-spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 300px;
            height: 300px;
            margin:-50px 0 0 -50px;
            -webkit-animation:$keyframe-name $spinning-time linear infinite;
            -moz-animation:$keyframe-name $spinning-time linear infinite;
            animation:$keyframe-name $spinning-time linear infinite; 
            background: url('../img/ninja-spinner/ninja-shuriken.svg');
            background-size: 300px 300px;
        }
        .ninja-loader {
            display: block;
            background: red;
            background: url('../img/ninja-spinner/ninja-logo.svg');
            width: 200px;
            height: 200px;
        }
    }

    &.small-spinner {
        width: 150px;
        margin: 0 auto;

        .ninja-spinner {
            width: 150px;
            height: 150px;
            margin: initial;
            background-size: 150px 150px;
        }

        .ninja-loader {
            width: 150px;
            height: 150px;
        }
    }

}

@-moz-document url-prefix() {
    .ninja-spinner {
        margin:-40px 0 0 -50px !important;
    }

    .small-spinner .ninja-spinner {
        margin: 10px 0 0 0 !important;
    }
    
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/*############################ NINJA LOADER END ###############################*/