@import url('https://fonts.googleapis.com/css?family=Mulish:200,300,400,500,600,700,800,900|Mulish:200italic,300italic,400italic,500italic,600italic,700italic,800italic,900italic&display=swap');
/// Input


input[type=text] {
    -webkit-appearance: textarea;
    -webkit-rtl-ordering: logical;
    flex-direction: column;
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    
    background: #FFFFFF;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #D0D0D2;
    //margin-bottom: 26px;
    color: #3E3D4B;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.group-rom .first-part, .group-rom .third-part {
    padding: 10px;
    display: table-cell;
    vertical-align: middle;
}

.group-rom .first-part {
    width: 150px;
    font-weight: bold;
}

.group-rom .second-part {
    padding: 10px 0px !important;
    display: table-cell;
    vertical-align: top;
}

.group-rom .first-part, .group-rom .second-part, .group-rom .third-part {
    border-bottom: 0px solid #ddd !important;
}

// search input

#consoleHeader .main-section .main-section-container .search-container input {
    outline: none !important;
    border: none;
    font-family: "Mulish", sans-serif;
}

//


/// Placeholder color

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #d4d4d4;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #d4d4d4;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #d4d4d4;
}
:-moz-placeholder { /* Firefox 18- */
  color: #d4d4d4;
}

/// Container

#form-container {
    width: 100%;
    background-color: #c0c0c017;
    border-radius: 10px;
    border: #eaeaea 1px solid;
}

.form-header {
    background-color: #ececec;
    padding: 10px;
}

// Disable checkbox in ag-grid
.disable-ag-row-checkbox {
    .ag-selection-checkbox .ag-icon-checkbox-unchecked {
        pointer-events: none;
        border-radius: 3px;
        background: #eaeaea;
        width: 16px;
    }
}