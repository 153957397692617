@import "../bootstrap/mixins";

//
// Loading indicator
// --------------------------------------------------

$color-loading-indicator-text:        #999999;
$color-stripe-loader:                 #3498db;

.loading-indicator {
    padding: 20px 20px 20px 60px;
    color: $color-loading-indicator-text;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
    text-align: left;
    z-index: 10;

    > span {
        background: transparent url('../img/loader.gif') no-repeat 0 50%;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -20px;
        left: 0;
        display: block;
    }
}

.list-title{
    color: #555;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
}

.loading-indicator-container {
    position: relative;
    min-height: 40px;

    .loading-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 0;

        > div {
            position: absolute;
            top: 50%;
            margin-top: -0.65em;
        }
    }
}

html {
    .loading-indicator > span {
        background-size: 35px 35px;
        background: url('../img/loader-transparent.svg') 50% 50%;
        @include animation(spin 1s linear infinite);
    }

    .loading-indicator.is-opaque,
    .loading-indicator-container.is-opaque .loading-indicator {
        > span {
            background-image: url('../img/loader.svg');
        }
    }
}

// Sizes

.loading-indicator-container.size-small {
    min-height: 20px;
}

.loading-indicator.size-small,
.loading-indicator-container.size-small .loading-indicator {
    padding: 16px 16px 16px 30px;
    font-size: 11px;
    > span {
        height: 20px;
        width: 20px;
        margin-top: -10px;
    }
}

html {
    .loading-indicator.size-small,
    .loading-indicator-container.size-small .loading-indicator {
        > span {
            background-size: 20px 20px;
        }
    }
}

// Centered

.loading-indicator.indicator-center,
.loading-indicator-container.indicator-center .loading-indicator {
    padding: 20px;

    > span {
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
    }

    > div {
        text-align: center;
        position: relative;
        margin-top: 30px;
    }
}

// Inset

.loading-indicator.indicator-inset,
.loading-indicator-container.indicator-inset .loading-indicator {
    padding-left: 80px;

    > span {
        left: 20px;
    }
}

// Form fields

.loading-indicator-container.size-form-field,
.loading-indicator-container.size-input-text {
    min-height: 0;
}

.loading-indicator-container.size-form-field .loading-indicator,
.loading-indicator-container.size-input-text .loading-indicator {
    background-color: transparent;
    padding: 0;
    margin: 0;

    > span {
        padding: 0;
        margin: 0;
        left: auto;
        right: 7px;
        top: 6px;
        width: 23px;
        height: 23px;
        background-size: 23px 23px;
    }
}

.loading-indicator-container.size-form-field .loading-indicator > span {
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
}

//
// Spin animation
// --------------------------------------------------

@-moz-keyframes spin {
    0% { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(359deg); }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(359deg); }
}

@-o-keyframes spin {
    0% { -o-transform: rotate(0deg); }
    100% { -o-transform: rotate(359deg); }
}

@-ms-keyframes spin {
    0% { -ms-transform: rotate(0deg); }
    100% { -ms-transform: rotate(359deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }
}

@-moz-keyframes rspin {
    0% { -moz-transform: rotate(359deg); }
    100% { -moz-transform: rotate(0deg); }
}

@-webkit-keyframes rspin {
    0% { -webkit-transform: rotate(359deg); }
    100% { -webkit-transform: rotate(0deg); }
}

@-o-keyframes rspin {
    0% { -o-transform: rotate(359deg); }
    100% { -o-transform: rotate(0deg); }
}

@-ms-keyframes rspin {
    0% { -ms-transform: rotate(359deg); }
    100% { -ms-transform: rotate(0deg); }
}

@keyframes rspin {
    0% { transform: rotate(359deg); }
    100% { transform: rotate(0deg); }
}
