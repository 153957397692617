@import "../../variables";
@import "../../bootstrap/mixins/clearfix";
@import "../../bootstrap/_variables";
@import "../../utils/mq";

table#ipList {
    margin-top: 20px;

    td, th {
        &:nth-child(2) {
            width: 55%;
        }
        &:last-child {

            @include mq($until: tablet) {
                width: auto;
                max-width: 60px !important;
            }
        }
    }
}


#searchForm {
    .ajaxLoader {
        display: inline-block;
        opacity: 0;

        &:before {
            border-bottom: 1px solid $input-border;
        }
    }
}

.search-group {
    min-height: 75px;
    margin-top: 2px;

    @include mq($until: tablet) {
        display: table;

        &:after {
            display: table-row;
        }
    }

    & .search-form {
        text-align: right;
        padding-right: 10px;

        @include mq($from: tablet) {
            display: inline-block;
            margin-top: 6px;
        }

        @include mq($until: tablet) {
            display: table-cell;
            padding-top: 12px;
            padding-right: 13px;

            & input[type="text"] {
                width: 100%;
            }
        }

        &.error {
            & input[type="text"] {
                border-color: $alert-danger-text !important;
            }

            & .help-block.error {
                color: $alert-danger-text !important;
                position: absolute;
                left: 65px;

                @include mq($until: tablet) {
                    display: inline-block;
                }
            }
        }
    }

    & .input-group-btn {
        display: inline-block;

        @include mq($until: tablet) {
            display: table-cell;
        }
    }
}

#userIpWidget.pageContent {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.tab-icon {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.7em;
    margin-right: 5px;
    background-color: white;
}

.nav-pills-bn > li {
    float: left;
}

.nav-tabs-bn > li {
    float: left;
    margin: 9px 0 9px 0;
}

.nav-tabs-bn > li > a {
    line-height: 1.428571429;
    color: #8c8c8c;
    font-weight: bold;
}

.nav-tabs-bn > li.active > a, .nav-tabs-bn > li.active > a:hover, .nav-tabs-bn > li.active > a:focus {
    color: #3479b7;
    cursor: default;
    background-color: white;
}