@import "../../utils/mq";
@import "../../sb-admin-2/variables";

.os-panel {

    & h4 + div {
        margin: 25px auto 25px;
    }

    .systems {
        .center-block {
            width: 75px;
        }
    }

    .badge {
        background-color: transparent;
        color: inherit;
        border: 2px solid rgba(0,0,0,.87);
        width: 35px;
        height: 35px;
        line-height: 26px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
    }

    .panel-title {
        font-size: 14px;
    }

    .panel.panel-default {
        margin-right: 50px;

        .highlight {
            margin-right: 0;
        }
    }

    p {
        margin: 0 0 5px;
    }

    h5 {
        font-size: 16px;
    }

    #alternative {
        .nav-pills {
            li {
                float: none;

                &.active {

                    a {
                        border: none !important;
                        color: #fff !important;
                        border-radius: 4px;
                        background-color: $brand-primary !important;
                    }
                }
            }

            a {
                width: 100%;
                display: block;
            }
        }
    }

    .nav-pills-bn > li {
        float: left;
    }

    .nav-pills-bn > li > a {
        line-height: 1.428571429;
        border: 1px solid #dedede;
        border-radius: 0;
        background-color: #efefef;
        color: #8c8c8c;
        font-weight: bold;
    }

    .nav-pills-bn > li.active > a, .nav-pills-bn > li.active > a:hover, .nav-pills-bn > li.active > a:focus {
        color: #3479b7;
        cursor: default;
        background-color: white;
        border-bottom: white;
    }

    .nav-pills-bn > li {
        float: left;
    }

    .nav-pills-bn.nav-justified {
        width: 100%;
    }

    .nav-pills-bn.nav-justified > li {
        float: none;
    }

    .nav-pills-bn.nav-justified > li > a {
        text-align: center;
        margin-bottom: 5px;
    }    
}

.clipboard {
    position: relative;
    display: none;
}

.btn-clipboard {
    padding: 5px 8px;
    font-size: 12px;
    color: #ccc;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 0 4px 0 4px;
}

@media (min-width: 768px) {
    .clipboard {
        display: block;
    }
    
    .clipboard .btn-clipboard {
        border-top-right-radius: 0;
    }
}

td.server-heading-bg {
    background-color: #f5f5f5
}

td.phishing-url {
	max-width: 50px; 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis; 
}

td.phishing-info {
	max-width: 100px;  
	overflow: hidden; 
	text-overflow: ellipsis;
        word-break: break-word;
}