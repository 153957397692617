$color-media-list-hover-bg: #4ea5e0;

@mixin media-selected-tiles() {
    a {
        text-decoration: none;
    }

    .icon-container {
        background: $color-media-list-hover-bg !important;
        border-color: #2581b8;

        i, p {
            color: #ecf0f1;
        }
    }

    h4 {
        color: #2581b8;
    }
}

@mixin media-selected-list() {
    background: $color-media-list-hover-bg !important;

    a {
        text-decoration: none;
    }

    i, p.description {
        color: #ecf0f1;
    }

    h4 {
        color: white;
        text-decoration: none;
    }

    .icon-container {
        border-right-color: $color-media-list-hover-bg !important;
    }
}

.list-container {
    position: relative;
    z-index: 100;

    .no-data {
        font-size: 13px;
    }

    p.no-data {
        padding: 0 20px 20px 20px;
    }

    li.no-data {
        padding-top: 20px;
        display: block !important;
        width: 100% !important;
        border: none !important;
        background: transparent !important;
        cursor: default !important;
    }

    table.table.data {
        tbody tr:not(.no-data):active td {
            background: #4ea5e0 !important;
        }
    }
}

.media-list {
    padding: 0;
    margin: 0;
    @include user-select(none);

    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 20px 20px 0;
        overflow: hidden;
        cursor: pointer;
        border-radius: 3px;

        &:focus {
            outline: none;
        }

        .icon-container {
            display: table;

            i {
                color: #95a5a6;
                display: inline-block;
            }

            div {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }
        }

        .icon-container.image {
            > div.icon-wrapper {
                display: none;
            }
        }

        h4 {
            font-weight: 600;
            font-size: 13px;
            color: #2b3e50;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 150%;
            margin: 15px 0 5px 0;
            padding-right: 0;
            @include transition(padding 0.1s);

            position: relative;

            a {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 15px;
                color: #2b3e50;
                display: none;

                &:hover {
                    color: #0181b9;
                    text-decoration: none;
                }
            }
        }

        p.description {
            font-size: 12px;
            color: #95a5a6;
        }

    }

    &.list {

        li {
            height: 75px;
            width: 260px;
            border: 1px solid #ecf0f1;
            background: #f6f8f9;
        }

        li .icon-container {
            border-right: 1px solid #f6f8f9;
            width: 75px;
            height: 75px;
            float: left;

            i {
                font-size: 35px;
            }

            &.image {
                border-right: 1px solid #ecf0f1 !important;
            }

            p.thumbnail-error-message {
                display: none;
            }
        }

        .icon-wrapper {
            width: 75px;
        }

        li .info {
            margin-left: 90px;
        }

        li .image-placeholder {
            width: 75px;
            height: 75px;
        }

        li.selected {
            @include media-selected-list();
        }

        h4 {
            padding-right: 15px;

            a {
                right: 15px;
            }
        }
    }

    &.tiles {

        li {
            width: 167px;
            margin-bottom: 25px;
        }

        .icon-wrapper {
            width: 167px;
        }

        li .image-placeholder {
            width: 165px;
            height: 165px;
        }

        li .icon-container {
            width: 167px;
            height: 167px;
            border-radius: 3px;
            border: 1px solid #ecf0f1;
            overflow: hidden;
            background: #f6f8f9;

            i {
                font-size: 55px;
            }
        }

        li.selected {
            @include media-selected-tiles();
        }

        i.icon-chain-broken {
            margin-top: 47px;
        }

        p.description {
            margin-bottom: 0;
        }
    }
}

.media-list {
    &.tiles {
        li:hover {
            @include media-selected-tiles();
        }

        li:hover h4 {
            padding-right: 20px !important;
        }
    }

    &.list {
        li:hover {
            @include media-selected-list();
        }

        li:hover h4 {
            padding-right: 35px !important;
        }
    }

    li {
        &:hover h4 a {
            display: block;
        }
    }
}

@media (max-width: 1280px) {
    .media-list {
        &.list {
            li {
                width: 230px;
            }
        }
    }
}
