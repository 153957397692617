
.module-settings-panel {

  > .panel-heading {
    .pattern-name {
      overflow: hidden;
      word-wrap: break-word;
      display: block;
    }
  }

  .list-group-item {

    &.flex {
      display: flex;

      > span:first-child {
        flex: 1;
      }
    }

    div {
      overflow: hidden;
      word-break: break-work;
    }

    .forked-extra {
      color: green;
    }
    .forked-disabled {
      color: red;
    }
    .forked-none {
      font-weight: bold;
    }
      .forked-extra:before {
      content: "\f055";
    }
    .forked-disabled:before {
      content: "\f056";
    }
    .forked-none:before {
      content: "None";
    }

  }

  .panel-footer {
    background-color: #f5f5f5;
  }

  .partially-enabled {
    input:not(:checked) + .slider {
      background-color: darken($brand-warning, 0%);
    }

    input:disabled + .slider {
      background-color: lighten($brand-warning, 18%);
    }
  }

}
