@import "../bootstrap/mixins";

.flash-message {
    position: fixed;
    width: 500px;
    left: 50%;
    top: 13px;
    margin-left: -250px;
    color: #fff;
    font-size: 13px;
    padding: 10px 30px 10px 15px;
    z-index: 1050;
    word-wrap: break-word;
    font-weight: bold;
    text-align: center;
    @include box-shadow(0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24));
    border-radius: 3px;
    
    &.fade {
        @include opacity(0);
        @include transition('all 0.5s, width 0s');
        @include scale(0.9);
    }

    &.fade.in {
        @include opacity(1);
        @include scale(1);
    }
    
    &.success {
        background: #8da85e;
    }
    &.error {
        background: #cc3300;
    }
    &.warning {
        background: #f0ad4e;
    }
    &.info {
        background: #5fb6f5;
    }
    
    button {
        float: none;
        position: absolute;
        right: 10px;
        top: 8px;
        color: #fff;

        outline: none;

        &:hover {
            color: #fff;
        }
    }
}

@media (max-width: 480px) {
    .flash-message {
        left: 10px;
        right: 10px;
        top: 10px;
        margin-left: 0;
        width: auto;
    }
}
