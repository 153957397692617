/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

@import "utils/mq";

/* Toggle Styles */

#wrapper {
    padding-left: 0;
    margin-top: 50px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;

    &.with-console-header {
        margin-top: 180px;

        @media screen and (max-width: 1099px) {
            margin-top: 60px;
        }
    }
}

#sidebar-wrapper {
    z-index: 50;
    position: fixed;
    right: $sidebarWidth;
    width: 0;
    height: 100%;
    margin-right: -$sidebarWidth;
    //overflow-y: auto;
    background: #fff;
    -webkit-transition: width 0.2s ease;
    -moz-transition: width 0.2s ease;
    -o-transition: width 0.2s ease;
    transition: width 0.2s ease;
    top: 115px;
}


.nav-off-screen + #page-wrapper  #sidebar-wrapper {
    top: 0;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}



/* Sidebar Styles */

#sidebar-wrapper {

    .sidebar-nav {
        position: absolute;
        top: 0;
        width: $sidebarWidth;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #fff;
    }

    .sidebar-nav li {
        text-indent: 20px;
        line-height: 20px;
    }

    .sidebar-nav li a {
        display: block;
        text-decoration: none;
        color: #999999;
    }

    .sidebar-nav li a:hover {
        text-decoration: none;
        color: #fff;
        background: rgba(255,255,255,0.2);
    }

    .sidebar-nav li a:active,
        .sidebar-nav li a:focus {
        text-decoration: none;
    }

    .sidebar-nav > .sidebar-brand {
        height: 65px;
        font-size: 18px;
        line-height: 60px;
    }

    .sidebar-nav > .sidebar-brand a {
        color: #999999;
    }

    .sidebar-nav > .sidebar-brand a:hover {
        color: #fff;
        background: none;
    }

}

.toggled #wrapper {
    padding-right: 0;
}

.toggled #wrapper #sidebar-wrapper {
    width: 0;
    overflow: hidden;
}

#page-content-wrapper {
    padding: 20px;
    position: relative;
}

.toggled #wrapper #page-content-wrapper {
    position: relative;
    margin-right: 0;
}

.right-sidebar-toggle {
    .fa:before {
        content: "\f205";
    }
}

.toggled .right-sidebar-toggle {
    .fa:before {
        content: "\f204";
    }

    color: $text-color;
}

@include mq($until: tablet) {

    #sidebar-wrapper {
        background: #fff !important;
        position: absolute;

        .sidebar-nav {
            width: 100%;
        }
    }

    #wrapper {
        margin-top: 0;

        #page-wrapper {
            min-height: auto;

            & > div:not(#sidebar-wrapper) {
                display: none;
            }
        }

        .toggled &, .no-sidebar & {
            #page-wrapper {
                & > div:not(#sidebar-wrapper){
                    display: block;
                }
            }
        }

        padding-right: 0;
    }

    #sidebar-wrapper {
        width: 100%;
        background: $menuBgColor;
    }

    #page-wrapper {
        margin-top: 0;
    }

    .mobile #mobile-menu-toggle {
        background-color: $menuBgColor;
        color: #fff;
    }


}

@include mq($from: tablet) {

    #wrapper {
        padding-right: $sidebarWidth;
    }

    .no-sidebar #wrapper {
        padding-right: 0;
    }

    #sidebar-wrapper {
        width: $sidebarWidth;
        border: 0;
        border-left: 1px solid lighten($brand-default, 25%);
    }
}