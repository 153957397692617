/// Default values
$fontSize: 18px;                        // Default font size
$fontFamily: 'Open Sans', sans-serif;   // Default font family
$bodyBgColor: #F4F7FA;                  // Background color
$alterBgColor: #252933;                 // Alternative background color
$textColor: rgba(#000000, .87);         // Default text color
$linkColor: #1F222D;                    // Default link color
$minBodyWidth: 480px;                   // Min body width
$sidebarWidth: 360px;                   // Default sidebar width
$navbarBorderColor: #ddd;               // Navigation border default color

/// Color codes
$defaultColor: #555;                    // Default color
$primaryColor: #4da7e8;                 // Primary color
$successColor: #15C993;                 // Success color 15C993 -> 5cb85c
$infoColor: #76ADFF;                    // Info color 76ADFF -> 5fb6f5
$dangerColor: #E84545;                  // Danger color #F18F8F #ED6A6A #E84545 #BA3737 #8B2929 ->  #c42026 
$warningColor: #CCA329;                 // Warning color

$alertWarningColor: $warningColor;      // Warning alert text color
$alertWarningBgColor: #FAEBCC;          // Warning alert background color
$alertWarningBorderColor: #F5DAA2;      // Warning alert border color
$alertSuccessColor: $successColor;      // Success alert text color
$alertSuccessBgColor: #D6E9C6;          // Success alert background color
$alertSuccessBorderColor: #B7DD97;      // Success alert border color
$alertInfoColor: #31708F;               // Info alert text color
$alertInfoBgColor: #BCE8F1;             // Info alert background color
$alertInfoBorderColor: #90D2E0;         // Info alert border color
$alertDangerColor: $dangerColor;        // Danger alert text color
$alertDangerBgColor: #EBCCD1;           // Danger alert background color
$alertDangerBorderColor: #DB9DA7;       // Danger alert border color

$labelDefaultColor: #555;               // Default label color
$labelPrimaryColor: $primaryColor;      // Primary label color
$labelSuccessColor: $successColor;      // Success label color
$labelInfoColor: $infoColor;            // Info label color
$labelWarningColor: $warningColor;      // Warning label color
$labelDangerColor: $dangerColor;        // Danger label color

$btnDefaultHoverColor: lighten($defaultColor, 50%);
$btnPrimaryHoverColor: #254D9C;         // Primary button hover color
$btnSuccessHoverColor: #00655A;         // Success button hover color
$btnInfoHoverColor: #6925A5;            // Info button hover color
$btnWarningHoverColor: #DC5B32;         // Warning button hover color
$btnDangerHoverColor: #9C151A;          // Danger button hover color

$menuBgColor: #F7F6F7;                  // Menu background color
$menuColor: #1F222D;                    // Menu item background color
$menuHoverColor: #1C1F28;               // Menu item hover color
$menuDefaultWidth: 220px;               // Menu default width
$menuTextColor: darken(#fff, 20%);      // Menu text color

/// Media Query breakpoints
$mqMobileWidth: 480px;                  // Mobile width
$mqTabletWidth: 768px;                  // Tablet width
$mqDesktopWidth: 992px;                 // Desktop width
$mqLargeDesktopWidth: 1200px;           // Large desktop width
$mqWideDesktopWidth: 1300px;            // Wide desktop width
$mqXWideDesktopWidth: 1600px;           // Extra wide desktop width

$mq-breakpoints: (
mobile:  $mqMobileWidth,                // Mobile view
tablet:  $mqTabletWidth,                // Tablet view
desktop: $mqDesktopWidth,               // Desktop view
large:   $mqLargeDesktopWidth,          // Large desktop view
wide:    $mqWideDesktopWidth,           // Wide desktop view
xwide:   $mqXWideDesktopWidth           // Extra wide desktop view
);

/// Spinners

$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;