/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
.credit-card-alert-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    background-color: #ffc107;
    margin: 0;
    border: none;
    
    a {
        color: inherit;
        text-decoration: underline;
    }
}

.beamerContainer{
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px !important;

    #beamerButton{
        font-size: large;
    }
}

.nav#side-menu-reseller {
    span, i{
        color: #000000;
    }

    li:hover, li:focus{
        background: #7a7a7a;
    }
}

.reseller-table {
    td {
        background-color: transparent !important;
    }
}
