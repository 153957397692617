@import "../../utils/mq";

#serversTable {

    td {
        vertical-align: middle;
    }

    .label {
        font-size: 12px;
        padding: 3px 5px;
        display: inline-block;
        margin-top: 3px;
    }

    .server-name {
        font-weight: 600;
    }

    tr.gradeA td #license {
        font-weight: bold;

        &.ok, &.no_payment {
            color: #31ac5f;
            .fa:before{
                content: $fa-var-check-circle;
            }
        }

        &.trial, &.basic {
            color: #E2B70C;
            .fa:before{
                content: $fa-var-exclamation-circle;
            }
        }

        &.free, &.inactive {
            color: #F7594A;
            .fa:before{
                content: $fa-var-circle;
            }
        }

        .fa {
            margin-right: 5px;
        }
    }
}

.server-card {
    min-height: 275px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    
    .server-title {
        position: relative;

        .status-indicator {
            position: absolute;
        }

        .status-indicator ~ a {
            margin-left: 20px;
        }
    }

    &.status-danger {

        h4, h4 a, .controller > a > i {
            background-color: $brand-danger;
            color: #fff;
        }

    }

    &.status-warning {

        h4, h4 a, .controller > a > i {
            background-color: $brand-warning;
            color: #fff;
        }

    }

    .server-details {
        padding: 15px;
    }

    .server-details {
        position: relative;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        
        .module-indicator {
            display: flex;
            justify-content: center;
            margin-top: 13px;
        }
        
        .server-stat {
            position: relative;
            font-size: 12px;
            min-width: 12px;

            > .material-switch {
                position: absolute;
                top: -5px;
                right: 0;

                h4 {
                    display: inline-block;
                    margin-left: 5px;
                    line-height: 21px;
                }
            }
            
            > .row {
                padding: 2px 0;
            }

            .badge {
                font-size: 10px;
                padding: 0;
                cursor: pointer;
            }
        }

        .controller.right {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 100;

            > a {
                text-decoration: none;
            }
        }

        h3, h4 {
            padding: 15px 50px 15px 20px;
            margin: -15px -15px 15px;
            border-radius: 3px 3px 0 0;
            font-weight: 600;

            a {
                display: block;
                text-decoration: none;
                line-height: 1.4;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }
        }
    }
    
    .stat-content {
        background: #fff;

        .list-group { margin-bottom: 0; }

        .list-group-item {
            border: none;
            padding: 5px 15px;
            border-top: 1px solid #ddd;

            .row > div {
                font-size: 12px;
            }
        }
    }

    .more-btn, .less-btn {
        border: none;
    }

}

.searchbox {
    position: relative;
    width: 0;
    height: 30px;
    margin: 20px 0;
    overflow: hidden;
    float: right;
    transition: width 0.3s;

    &.searchbox-open {
        width: calc(100% - 70px);
    }

    .searchbox-input {
        top: 0;
        right: 0;
        outline: 0;
        width:100%;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        border: 1px solid;
        padding: 0 0 0 10px;
    }
}

.server-group-panel {
    padding: 10px 20px !important;

    .edit-btn {
        position: absolute;
        top: 10px;
        padding: 10px;
    }
}

.add-server-card {
    background: transparent;
    transition: all .2s;
    border: 1px dashed #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 250px;
    margin-bottom: 20px;
    
    &:hover {
        border: 1px dashed $brand-danger;
    }

    a {
        text-decoration: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 63px 0;

        &:before {
            background: #fff;
            color: $brand-danger;
            border-radius: 10px;
            font-size: 40px;
            font-weight: 500;
            line-height: 1;
            padding: 15px;
            margin: auto;
            width: 70px;
            display: block;
            content: "+";
        }

        h4 {
            width: 100%;
            display: block;
            margin-top: 20px;
        }

    }


}

.view-selector {

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    a {
        line-height: 40px !important;
        padding: 15px 20px !important;
    }
}

#logs {
    .logs {
        overflow: hidden;
        overflow-y: auto;
        background-color: #000 !important;
        color: #fff;

        div {
            span.date {
                color: gray;
            }
            span.severity {
                color: silver;
            }
            span.info {
                color: rgb(0,128,0);
            }
            span.warn {
                color: rgb(255,165,0);
            }
            span.error {
                color: rgb(255,0,0);
            }
        }
    }
}

#cancelSubscription {
    
    .modal-content {
        border-radius: 0;
        background: #282C37;
        color: #fff;
    }
     
    .modal-body {
        overflow: hidden;
    }
    
    .step-content {
        position: relative;
        
        label {
            font-weight: normal;
            margin-bottom: 8px;
            font-size: 15px;
        }
        
        p {
            color: #fff;
        }
    }
    
    .sad-ninja {
        position: absolute;
        right: -20px;
        bottom: -50px;
        width: 220px;
    }
    
    .modal-header {
        border-bottom: none;
        
        .modal-title {
            color: #fff;
            font-size: 20px;
        }
        
        .close {
            color: #fff;
            @include opacity(1);
        }
    }
}

#server-groups {
    position: relative;
    
    & > li.dropdown {
        height: 62px;
        
        & > a {
            line-height: 40px;
        }
    }

    & > li, & .dropdown-menu > li {
    
        & > a > span {
            line-height: 20px;
            display: block;
            text-align: center;
        }

        .group_name {
            font-size: 14px;
            font-weight: 600;
        }

        .server_count {
            font-size: 12px;
        }
    }
}

.logs  {
    min-height: 300px;
    height: 60vh;
    padding: 15px;
    background: lighten($brand-default, 30%) !important;
}

a.more-link {
    font-weight: normal;
    text-decoration: underline;
}

.nav-pills-bn > li {
    float: left;
    margin: 9px 0 9px 0;
}

.nav-pills-bn > li > a {
    line-height: 1.428571429;
    color: #8c8c8c;
    font-weight: bold;
}

.nav-pills-bn > li.active > a, .nav-pills-bn > li.active > a:hover, .nav-pills-bn > li.active > a:focus {
    color: #3479b7;
    cursor: default;
    background-color: white;
}

.nav-pills-bn > li {
    float: left;
}