
li#fileIncident:hover {
    background-color: #f5f5f5;
}

@media (max-width: 768px) {
    
    li#fileIncident .btn-clipboard {
        display: none;
    }
}

#fileIncidentResult .log-only-incident #restore-file-form button {
    display: none;
}

#fileIncidentResult .log-only-incident:hover {
    background-color: #F0F6BA;
}

#fileIncidentResult .log-only-incident {
   background-color: #F0F6BA;
} 

#fileIncidentResult .log-only-incident .log-only, 
#fileIncidentResult .is-log-state .fa-edit {
    color: green;
}
#fileIncidentResult .log-only-incident .btn-clipboard  {
    color: #7d7d7d;
}