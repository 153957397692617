/// Global font

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,300);
@import url('https://fonts.googleapis.com/css?family=Mulish:200,300,400,500,600,700,800,900|Mulish:200italic,300italic,400italic,500italic,600italic,700italic,800italic,900italic&display=swap');

/// Global styles

* {
    outline: none;
}

html {
    font-size: $fontSize;
    line-height: 1.666rem;
}

p, h1, h2, h3, h4 {
    color: $textColor;
}

#main-content {
    background: $body-bg;
    min-height: 460px;
    margin-left: 10px;
}

a, a:hover, a:focus {
    color: $linkColor;
}

/// Input

input {
    @include box-shadow(none !important);
}

input[type=text],
input[type=email],
input[type=password],
input.form-control {
    background: #FFFFFF;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #D0D0D2;
    //margin-bottom: 26px !important;
    color: #3E3D4B;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input.form-control:focus {
    outline: 1px solid #3E3D4B;
}


input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover,
input.form-control:hover {
    outline: 1px solid #3E3D4B;
}

input.input-sm {
    padding-top: 6px;
    padding-bottom: 4px;
}

/// Buttons

.btn {
    padding: 6px 18px;
    font-weight: 500;
    outline: none !important;
    border-radius: 0;
    
    &:not(.btn-link):hover { }
    &:not(.btn-link):active { }
}

.btn-sm {
    padding: 5px 10px;
}

.btn-xs {
    padding: 2px 6px 1px;
}

.btn-rounded {
    border-radius: 4px;
}

.btn-dark-outlined {
    border: 2px solid #333;
}

.btn-icon {
    display: inline-block;
    height: 36px;
    font-size: 21px;
    background: transparent;
    border: none;
    outline: none;

    &.danger:hover:before {
        color: $brand-danger;
    }
}

/// Labels

.label-default { background-color: $labelDefaultColor; }
.label-primary { background-color: $labelPrimaryColor; }
.label-success { background-color: $labelSuccessColor; }
.label-info { background-color: $labelInfoColor; }
.label-warning { background-color: $labelWarningColor; }
.label-danger { background-color: $labelDangerColor; }
.label-block { display: block; }

/// Forms

.form-control {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(#000,.2);
    box-shadow: none;
    border-radius: 0;
    outline: none;
}

select.form-control, textarea.form-control {
    border-width: 1px 1px 1px 1px !important;
}

.form-control:focus {
    box-shadow: none;
    border-color: #000;
}

.form-group {
    & .input-group-addon {
        border-radius: 0 !important;
        border-width: 0 0 1px 0;
        padding-top: 9px;
        border-color: lighten($defaultColor, 30%) !important;
    }

    .input-group-btn .btn {
        margin-top: 1px !important;
        padding: 6px 18px !important;
    }
}

.dropdown-menu-scale {
    display: block;
    opacity: 0;
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all 250ms cubic-bezier(.24,.22,.015,1.56);
    transition: all 250ms cubic-bezier(.24,.22,.015,1.56);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;
}

.open > .dropdown-menu-scale {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    pointer-events: auto;
    display: block !important
}

/// Alerts

.alert {
    border-radius: 0;
}

// Progress bar

.progress-xs {
    height: 6px;
}

.progress-sm {
    height: 10px;
}

.progress-sm .progress-bar {
    font-size: 10px;
    line-height: 1em;
}

/// Panels

.panel {

    & .panel-footer {
        background-color: transparent;
    }
}

/// Navbar

.nav-inverse {
    background-color: $brand-primary;

    li {
        &.active {
            a {
                font-weight: 600;
            }
        }

        a {
            color: #fff !important;
            border-bottom: 0 !important;
        }
    }
}

.panel-heading {
    .nav {
        margin: -10px -15px -11px;
        border: none;

        > li > a {
            border-radius: 0;
            margin: 0;
            border-top-width: 0;
        }
    }

    .nav-tabs {
        > li {
            &:first-child {
                > a {
                    border-left-width: 0;
                }
            }

            > a {
                &:hover, &:focus {
                    border-top-width: 0;
                }
            }
        }
    }
}

.icon-muted {
    color: #666;
    text-shadow: 0 1px 1px #fff;
}

/// Tree path

ul.tree-path {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    
    li {
        display: inline-block;
        font-size: 13px;
        white-space: nowrap;

        &:before {
            text-decoration: inherit;
            content: "/\00a0";
            display: inline-block;
            font-size: 13px;
            position: relative;
            top: 1px;
            color:#95a5a6
        }

        &:last-child {
            a {
                cursor: default;
            }
        }
    }
}

/// Jumbotron

.jumbotron {
    padding-left: 30px;
    padding-right: 30px;
}

/// Others

.text-small {
    font-size: 12px;
}

.font-normal {
    font-weight: normal;
}

.list-group-item {
    border-radius: 0 !important;
}

.dropdown-menu {
    border-radius: 0 !important;
    box-shadow: none !important;
}

.popover, .popover-title, .tooltip * {
    border-radius: 0;
}

.navbar-toggle {
    border-radius: 0 !important;
    border-width: 0;
    border-left-width: 1px;
    margin: 0;
    background: transparent;
    height: 50px;
    min-width: 50px;
    float: left;
    font-size: 16px;
}

.table-striped-vertical {
    > tbody {
        > tr {
            &:first-child td {
                border-top: 0 !important;
            }
            td:nth-of-type(odd) {
                background-color: $table-bg-accent;
            }
        }
    }
}

.table {
    td {
        word-break: break-all;
    }
}

.table-xl {
    td {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

.table-striped {
    th {
        border-bottom: 0 !important;
    }
}

table.table-striped-vertical {
    > tbody {
        > tr {
            td:first-child {
                font-weight: bold;
                @include mq($until: desktop) {
                    width: 50% !important;
                }
                @include mq($from: desktop) {
                    width: 20% !important;
                }
            }
        }
    }
}

.error,
div.form div.error label,
div.form label.error,
div.form span.error {
    color: $brand-danger;
}

div.form div.error input,
div.form div.error textarea,
div.form div.error select,
div.form input.error,
div.form textarea.error,
div.form select.error {
    background: $state-danger-bg;
    border-color: $brand-danger;
}


header {
    @include mq($from: desktop) {
        display: table;
        width: 100%;
    }
}

.nav-pills {
    &.pull-right, &.pull-left {
        @include mq($until: desktop) {
            float: none !important;
        }
    }

    li {
        @include mq($until: desktop) {
            & > a {
                padding-top: 12px;
            }

            &.active {
                border-color: transparent;
            }
        }

        & + li {
            @include mq($until: desktop) {
                margin-left: 0;
            }
        }
    }
}



.panel-subtitle {
    font-size: 16px;
    line-height: 40px;

    @include mq($until: desktop) {
        text-align: center;
        margin: 0 auto;
        display: block;

        & + .nav-pills {
            margin-bottom: 40px;
            margin-top: 20px;
        }
    }
}

.checkbox, .checkbox * {
    line-height: 1.42857143 !important;
}

.pageContentWrapper {

    .pageHeader {
        @include clearfix;
        margin-bottom: 20px;
        padding: 20px;
        background-color: #fff;
        @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
        
        > .row > .col-xs-12 {
            @include mq($until: tablet) {
                padding: 0;
                
                li a {
                    padding: 20px 10px;
                }
            }
        }

        .nav-pills, .nav-tabs {
            float: right;

                        
            &.nav-sm {
                > li > a {
                    padding: 18px 10px;
                }
            }

            &:not(.nav-sm) {
                > li > a {
                    padding: 22px 8px;
                }
            }

            @include mq($until: tablet) {
                float: none !important;
            }
        }

        .tabbable {
            .nav-pills, .nav-tabs {
                li > a {
                    padding: 15px;
                    height: 70px;
                }

                &.is-small li > a {
                    height: 50px;
                }
            }
        }

        &.no-bottom {
            padding-bottom: 0 !important;
        }
        
        &.no-left, .no-left {
            padding-left: 0 !important;
        }
        
        &.no-right, .no-right {
            padding-right: 0 !important;
        }

        &.no-top {
            padding-top: 0 !important;
            
            h3 {
                margin-top: 20px;
            }
        }

        h3 {
            margin: 0;
        }

        h3 + a.pull-right {
            margin-top: 2px;
        }
    }

    .pageContent {
        @include clearfix;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        margin: 0 20px 20px;
        position: relative;
        @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
    }
}

.panel > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}

.list-group-item.level-2, .panel.level-2 {
    padding-left: 35px;
}

.panel > .list-group {
    margin-bottom: 0;
}

.text-white {
    color: #fff !important;
}

.tabbable {
    border-top: 1px solid #e7e7e7;
}

.wrapper-body > .container {
    min-height: 100vh;
}

input.bordered {
    border-width: 1px;
}

.br-4 {
    border-radius: 4px;
}

.no-decorate {
    text-decoration: none !important;
}

.decorate {
    text-decoration: underline;;
}

.text-dark {
    color: #333;;
}

.no-data {
    padding: 18px 20px;
    text-align: center;
    margin: 0;
    
    &.p-small {
        padding: 10px 20px;
    }
}

.search-field {
    position: relative;

    input {
        text-indent: 15px;
    }

    .fa-search {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 13px;
        color: #666;;
    }
}

.box-shadow, .box-shadow .box, .box-shadow .box-color {
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
}

.info-button {
    display: inline-block;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: none !important;

    span {
        font-size: 20px;
        vertical-align: top;
    }
}

.pale {
    @include opacity(.7);
}

.incident {
    &.test {
        background: #dff0d8 !important;
        td {
            background: #dff0d8 !important;
        }
    }
}

.m-x-20 {
    margin-left: -20px;
    margin-right: -20px;
}

.flexbox {
    @include flexbox();
}

.align-center {
    display: flex;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.flex-1 {
    @include flex(1);
}

.overlay {
    position: absolute;
    background-color: white;
    opacity: 0.7;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
}

.full-center {
    position: absolute;
     top: 0; 
     bottom: 0; 
     left: 0; 
     right: 0;
     margin: auto; 
     z-index: 100; 
}
