$group-rom_firstPartWidth: 200px;
$group-rom_firstPartMinWidth: 120px;
$group-rom_thirdPartWidth: 260px;
$group-rom_thirdPartMinWidth: 180px;

.group-rom {
    display: table;
    width: 100%;

    .first-part, .second-part, .third-part {
        padding: 10px;
        border-bottom: 1px solid $table-border-color;
        display: table-cell;
        vertical-align: top;
    }

    > form {
        display: table-row;
    }

    &:last-child {
        .first-part, .second-part, .third-part {
            border-bottom: 0;
        }
    }

    &.bordered {
        .first-part, .second-part, .third-part {
            border-bottom: 1px solid $table-border-color;
        }
    }

    .first-part {
        width: $group-rom_firstPartWidth;
        font-weight: bold;

        @include mq($until: tablet) {
            width: $group-rom_firstPartMinWidth;
        }
    }
    .second-part {
        & .form-group {
            position: relative;
            top: 4px;

            .col-sm-3 {
                display: none;
            }

            .col-sm-9 {
                width: 100%;
                padding-left: 15px;
            }

            @include mq($until: tablet) {
                top: 0;
            }
        }
    }
    .third-part {
        width: $group-rom_thirdPartWidth;
        padding-right: 0;

        @include mq($until: tablet) {
            width: $group-rom_thirdPartMinWidth;
            padding-right: 0;

            & .btn {
                padding: 6px 12px 5px 12px !important;
            }
        }
    }

    .control-label {
        display: none;

        & + div {
            width: 100% !important;
        }
    }
    
    .help-block {
        margin-bottom: 0;
    }
}