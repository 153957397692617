.eventLogPanel {
    border: none;
    @include box-shadow(none);

    @include mq($until: desktop) {
        padding-left: 0;
        padding-right: 0;
    }

    @include mq($from: desktop) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    & * {
        text-indent: 0;
    }

    & .panel-body {
        padding: 0;
        margin: 0;
        height: 100%;
    }
}

header.eventLogHeader {
    padding: 10px 15px;
    margin: 0;
    color: #fff;
    background-color: $menuBgColor;
    border-radius: 0;

    & .title{
        float: left;
        line-height: 23px;
    }
}

header.eventLogHeader, #logs, #incidentTable {
    & #action, .playPause {
        width: 24px;
        height: 24px;
        cursor: pointer;
        font-family: 'FontAwesome';
        background: #fff;
        border-radius: 50%;
        color: $brand-danger;
        line-height: 24px;
        text-align: center;
    }
    
    .expantAllToggle {
        cursor: pointer;
    }
    
    & #action {
        float: right;
    }

    & #action.pause:before, .playPause.pause:before {
        content: "\f04c";
    }

    & #action.play:before, .playPause.play:before {
        content: "\f04b";
        padding-left: 1px;
    }
}

#logs {
    .playPause {
        margin-top: 10px;
        background-color: $brand-danger;
        color: #fff;
    }
}

#eventTable{
    padding: 15px;
    margin: 0;

    #eventLogs {        
        & .eventRow{
            display: none;
            overflow: hidden;

            & a, & * a {
                display: inline !important;
                color: darken($brand-default, 10%) !important;

                &:hover, &:active {
                    color: $brand-default !important;
                }
            }
        }
        
        & #serverNameRow {
            padding:6px 0 0 0;
        }

        & #serverNames{
            & a {
                color: darken($brand-default, 10%) !important;

                &:hover, &:active, &:focus {
                    color: $brand-default !important;
                }
            }
        }


        & #serverName {
            font-weight: bold;
            font-size: 13px;
        }

        & #explode {
            margin-right: 5px;
        }

        & #moreServerName {
            font-weight: bold;
            height: 18px;
            font-size: 12px;
        }

        & #moreServerNameButton {
            margin-left: 5px;
            border: 1px  solid grey;
            font-weight: 600;
            cursor: pointer;
            padding: 1px 5px 1px 5px;
            font-size: 12px;
            line-height: 15px;
        }

        & #messageDataRow {
            font-size: 11px;
            padding-top: 7px;
            line-height: 15px;

            & #message {
                float: left;
                width: 80%;
                font-size: 12px;
                letter-spacing: -0.5px;

                > a {
                    display: inline !important;
                }
            }

            & #message * {
                font-family: $font-family-monospace !important;
            }

            & #logTime {
                float: right;
                text-align: right;
                font-size: 12px;
                color: darken($brand-default, 15%);
                width: 20%;
                line-height: 16px;
            }
        }
    }
}

#logContainer {
    font-size: 12px;

    & > div, & > div * {
        font-family: $font-family-monospace !important;
    }

    .no-result {
        text-align: center;
    }
    
    a {
        color: lighten($textColor, 45%);
        
        &:hover {
            color: lighten($textColor, 50%);
        }
    }
}

#incidentTable {
    tr td:first-child {
        width: 90px;
    }
}